
* {
    margin: 0;
    padding: 0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    outline: 0
}

html {
    scroll-behavior: smooth
}

body,html {
    overflow-x: hidden
}

ol,ul {
    list-style: none
}

a {
    text-decoration: none
}

a,button,input,textarea {
    background: 0 0;
    color: inherit;
    font-family: inherit;
    font-size: inherit;
    border: none;
    cursor: pointer;
    -webkit-transition: .3s ease-in-out;
    -o-transition: .3s ease-in-out;
    transition: .3s ease-in-out
}

input[readonly],textarea[readonly] {
    cursor: default
}

textarea {
    resize: none
}

img {
    display: block;
    width: 100%;
    -o-object-fit: cover;
    object-fit: cover
}

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0
    }

    100% {
        opacity: 1
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0
    }

    100% {
        opacity: 1
    }
}

@-webkit-keyframes fadeOut {
    0% {
        opacity: 1
    }

    100% {
        opacity: 0
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1
    }

    100% {
        opacity: 0
    }
}

.fadeIn {
    -webkit-animation: .4s ease-in-out fadeIn;
    animation: .4s ease-in-out fadeIn
}

.fadeOut {
    -webkit-animation: .4s ease-in-out fadeOut;
    animation: .4s ease-in-out fadeOut
}

html.fixed {
    overflow: hidden
}

body {
    background: #fbf8fe;
    font-family: Lato,sans-serif;
    color: #485870;
    font-size: 16px;
    line-height: 1.6
}

.h1,.h2,.h3,.h4,.h5,.h6,h1,h2,h3,h4,h5,h6 {
    font-family: "Barlow Semi Condensed",sans-serif;
    color: #2b3543;
    font-weight: 600;
    line-height: 1.1
}

.h1,h1 {
    font-size: 48px
}

.h2,h2 {
    font-size: 40px
}

.h3,h3 {
    font-size: 38px
}

.h4,h4 {
    font-size: 28px
}

.h5,h5 {
    font-size: 22px
}

.h6,h6 {
    font-size: 16px;
    font-weight: 600
}

main {
    position: relative
}

main>.underlay {
    z-index: -1;
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    background: -o-linear-gradient(221.29deg,rgba(255,255,255,.0001) -62.66%,#fcf9f9 71.21%),-o-linear-gradient(38.61deg,#6a11cb 20.14%,#2575fc 93.63%),url(../img/placeholder.jpg) center right/cover no-repeat;
    background: linear-gradient(228.71deg,rgba(255,255,255,.0001) -62.66%,#fcf9f9 71.21%),linear-gradient(51.39deg,#6a11cb 20.14%,#2575fc 93.63%),url(../img/placeholder.jpg) center right/cover no-repeat;
    background-blend-mode: lighten,color,normal;
    max-height: 1480px;
    height: 100%
}

main>.underlay:after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: -o-linear-gradient(284.28deg,rgba(251,248,254,.0001) -51.77%,#fbf8fe 88.68%);
    background: linear-gradient(165.72deg,rgba(251,248,254,.0001) -51.77%,#fbf8fe 88.68%)
}

.text {
    font-size: 16px;
    font-weight: 700;
    line-height: 1.3;
    color: #29323c
}

.text--sm {
    font-size: 14px
}

.container {
    max-width: 1110px;
    padding: 0 20px
}

.collapse:not(.show) {
    display: none
}

.collapsing {
    height: 0;
    overflow: hidden;
    -webkit-transition: height .3s ease-in-out;
    -o-transition: height .3s ease-in-out;
    transition: height .3s ease-in-out
}

.fade {
    -webkit-transition: opacity .3s ease-in-out;
    -o-transition: opacity .3s ease-in-out;
    transition: opacity .3s ease-in-out
}

.fade:not(.show) {
    opacity: 0
}

.tab-content>.tab-pane {
    display: none
}

.tab-content>.active {
    display: block
}

.panel {
    margin-bottom: 30px
}

.panel_block .link {
    line-height: 1;
    font-size: 22px;
    font-family: "Barlow Semi Condensed",sans-serif;
    color: #3d53eb
}

.panel_block .custom-select-opener {
    margin-bottom: 0;
    padding: 0 55px 0 30px
}

.panel_block .icon {
    margin-right: 10px;
    position: relative;
    top: 2.5px
}

.btn {
    border-radius: 12px;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 14px;
    line-height: 1;
    height: 54px;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0 30px
}

.btn .text {
    line-height: 1;
    position: relative;
    top: 1px
}

.btn--bordered {
    border: 1px solid #ededed
}

.btn--bordered:focus,.btn--bordered:hover {
    border-color: #2575fc
}

.btn--arrow {
    text-transform: uppercase;
    color: #3d53eb;
    font-size: 14px;
    line-height: 1;
    font-weight: 900;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.btn--arrow .icon {
    -webkit-transition: margin-left .3s ease-in-out;
    -o-transition: margin-left .3s ease-in-out;
    transition: margin-left .3s ease-in-out;
    margin-left: 10px;
    font-size: 14px
}

.btn--arrow:focus .icon,.btn--arrow:hover .icon {
    margin-left: 15px
}

.btn--yellow {
    background: #f9d423;
    color: #29323c
}

.btn--yellow:focus,.btn--yellow:hover {
    -webkit-box-shadow: 0 2px 7px rgba(255,255,255,.4);
    box-shadow: 0 2px 7px rgba(255,255,255,.4)
}

.btn--gradient {
    background: -o-linear-gradient(338.59deg,#ff0844 24.85%,#ff8b67 95.39%);
    background: linear-gradient(111.41deg,#ff0844 24.85%,#ff8b67 95.39%);
    position: relative;
    overflow: hidden;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transition: .3s ease-in-out;
    -o-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
    -webkit-box-shadow: 0 2px 4px rgba(43,53,67,0);
    box-shadow: 0 2px 4px rgba(43,53,67,0)
}

.btn--gradient .text {
    position: relative;
    z-index: 2;
    color: #fff;
    line-height: 1;
    font-size: 14px
}

.btn--gradient:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: -o-linear-gradient(338.59deg,#ff8b67 24.85%,#ff0844 95.39%);
    background: linear-gradient(111.41deg,#ff8b67 24.85%,#ff0844 95.39%);
    -webkit-transition: opacity .3s ease-in-out;
    -o-transition: opacity .3s ease-in-out;
    transition: opacity .3s ease-in-out;
    opacity: 0;
    z-index: 1
}

.btn--gradient:focus:after,.btn--gradient:hover:after {
    opacity: 1
}

.courses-tags .tag {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 16px;
    line-height: 1;
    font-weight: 700;
    border: 1px solid #d5d5d5;
    border-radius: 10px;
    height: 40px;
    padding: 0 20px;
    margin: 10px 10px 0 0;
    -webkit-transition: .3s ease-in-out;
    -o-transition: .3s ease-in-out;
    transition: .3s ease-in-out
}

.courses-tags .tag.current,.courses-tags .tag:focus,.courses-tags .tag:hover {
    color: #fff;
    background: #2b3543
}

.card {
    position: relative;
    overflow: hidden;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    border-radius: 12px;
    background: #fff;
    -webkit-box-shadow: 0 2px 14px rgba(216,217,255,.66);
    box-shadow: 0 2px 14px rgba(216,217,255,.66);
    text-align: center;
    padding: 40px 30px!important;
    height: 100%;
    cursor: pointer;
    -webkit-transition: .4s ease-in-out;
    -o-transition: .4s ease-in-out;
    transition: .4s ease-in-out
}

.card .content {
    position: relative;
    z-index: 2;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.card:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: -o-linear-gradient(307.13deg,#6a11cb 12.55%,#2575fc 108.54%);
    background: linear-gradient(142.87deg,#6a11cb 12.55%,#2575fc 108.54%);
    opacity: 0;
    -webkit-transition: opacity .4s ease-in-out;
    -o-transition: opacity .4s ease-in-out;
    transition: opacity .4s ease-in-out
}

.card_media {
    background: -o-linear-gradient(316.28deg,#6a11cb 21.85%,#2575fc 104.32%);
    background: linear-gradient(133.72deg,#6a11cb 21.85%,#2575fc 104.32%);
    border-radius: 12px;
    width: 74px;
    height: 74px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-bottom: 10px;
    -webkit-transition: .4s ease-in-out;
    -o-transition: .4s ease-in-out;
    transition: .4s ease-in-out;
    position: relative;
    overflow: hidden
}

.card_media:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: #fff;
    opacity: 0;
    -webkit-transition: opacity .4s ease-in-out;
    -o-transition: opacity .4s ease-in-out;
    transition: opacity .4s ease-in-out;
    z-index: 1
}

.card_media .icon {
    position: relative;
    z-index: 2;
    color: #fff;
    font-size: 30px;
    -webkit-transition: color .4s ease-in-out;
    -o-transition: color .4s ease-in-out;
    transition: color .4s ease-in-out
}

.card_main-title {
    margin: 0 auto 10px;
    -webkit-transition: color .4s ease-in-out;
    -o-transition: color .4s ease-in-out;
    transition: color .4s ease-in-out;
    font-size: 26px
}

.card_main-text,.card_main-title {
    max-width: 285px
}

.card:hover {
    color: #fff;
    -webkit-box-shadow: 0 2px 14px rgba(112,115,255,.75);
    box-shadow: 0 2px 14px rgba(112,115,255,.75)
}

.card:hover .card_media:after,.card:hover:after {
    opacity: 1
}

.card:hover .card_media .icon {
    color: #3d53eb
}

.card:hover .card_main-title {
    color: #fff
}

.course-card_wrapper {
    background: #fff;
    -webkit-box-shadow: 0 2px 14px rgba(216,217,255,.66);
    box-shadow: 0 2px 14px rgba(216,217,255,.66);
    border-radius: 12px;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-transition: .3s ease-in-out;
    -o-transition: .3s ease-in-out;
    transition: .3s ease-in-out
}

.course-card_wrapper .bottom,.course-card_wrapper .top {
    padding: 30px
}

.course-card_wrapper .top {
    padding-right: 10px;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1
}

.course-card_wrapper .top_icon {
    width: 64px;
    height: 64px;
    margin-right: 15px;
    border-radius: 12px;
    color: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    line-height: 1;
    font-size: 24px
}

.course-card_wrapper .top_icon--blue {
    background: -o-linear-gradient(288.59deg,#6a11cb 13.53%,#2575fc 81.8%);
    background: linear-gradient(161.41deg,#6a11cb 13.53%,#2575fc 81.8%)
}

.course-card_wrapper .top_icon--orange {
    background: -o-linear-gradient(135.36deg,#f9d423 -11.62%,#f83600 85.97%);
    background: linear-gradient(314.64deg,#f9d423 -11.62%,#f83600 85.97%)
}

.course-card_wrapper .top_icon--sky {
    background: -o-linear-gradient(135deg,#0acffe 0,#495aff 100%);
    background: linear-gradient(315deg,#0acffe 0,#495aff 100%)
}

.course-card_wrapper .top_icon--pink {
    background: -o-linear-gradient(315.4deg,#ff0844 19.3%,#ffb199 160.21%);
    background: linear-gradient(134.6deg,#ff0844 19.3%,#ffb199 160.21%)
}

.course-card_wrapper .top_title {
    margin-bottom: 5px
}

.course-card_wrapper .top_author {
    margin: 5px 0 8px;
    font-weight: 700;
    color: #868686
}

.course-card_wrapper .top_details {
    font-weight: 900;
    color: #3d53eb
}

.course-card_wrapper .top_author,.course-card_wrapper .top_details {
    font-size: 14px;
    line-height: 1
}

.course-card_wrapper .top .wrapper {
    width: calc(100% - 80px)
}

.course-card_wrapper .bottom_btn {
    padding: 0;
    width: 100%
}

.course-card_wrapper .pricing {
    background: #e9f1fe;
    padding: 10px;
    text-align: center
}

.course-card_wrapper .pricing_price {
    font-size: 20px;
    line-height: 1.1
}

.course-card_wrapper:hover {
    -webkit-box-shadow: 0 2px 14px rgba(137,139,255,.5);
    box-shadow: 0 2px 14px rgba(137,139,255,.5)
}

.field {
    height: 54px;
    background: #fff;
    border: 1px solid #d5d5d5;
    border-radius: 12px;
    width: 100%;
    padding: 19px 45px 18px 20px;
    font-size: 16px;
    line-height: 1;
    -webkit-transition: .3s ease-in-out;
    -o-transition: .3s ease-in-out;
    transition: .3s ease-in-out
}

.field::-webkit-input-placeholder {
    color: #979797;
    -webkit-transition: opacity .3s ease-in-out;
    transition: opacity .3s ease-in-out;
    font-weight: 400!important
}

.field::-moz-placeholder {
    color: #979797;
    -moz-transition: opacity .3s ease-in-out;
    transition: opacity .3s ease-in-out;
    font-weight: 400!important
}

.field:-ms-input-placeholder {
    color: #979797;
    -ms-transition: opacity .3s ease-in-out;
    transition: opacity .3s ease-in-out;
    font-weight: 400!important
}

.field::-ms-input-placeholder {
    color: #979797;
    -ms-transition: opacity .3s ease-in-out;
    transition: opacity .3s ease-in-out;
    font-weight: 400!important
}

.field::placeholder {
    color: #979797;
    -webkit-transition: opacity .3s ease-in-out;
    -o-transition: opacity .3s ease-in-out;
    transition: opacity .3s ease-in-out;
    font-weight: 400!important
}

.field:focus {
    border-color: #e1e0e0
}

.field:focus::-webkit-input-placeholder {
    opacity: 0
}

.field:focus::-moz-placeholder {
    opacity: 0
}

.field:focus:-ms-input-placeholder {
    opacity: 0
}

.field:focus::-ms-input-placeholder {
    opacity: 0
}

.field:focus::placeholder {
    opacity: 0
}

.field.error {
    border-color: red
}

input[type=checkbox] {
    position: absolute;
    left: -9999px
}

input[type=checkbox]+label {
    position: relative;
    padding-left: 26px;
    cursor: pointer;
    display: inline-block;
    -webkit-transition: color .4s ease-in-out;
    -o-transition: color .4s ease-in-out;
    transition: color .4s ease-in-out;
    line-height: 1
}

input[type=checkbox]:checked+label:before,input[type=checkbox]:not(:checked)+label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    width: 18px;
    height: 18px;
    border-radius: 4px;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%)
}

input[type=checkbox]:checked+label:before {
    border: 1px solid #485870
}

input[type=checkbox]:not(:checked)+label:before {
    border: 1px solid #979797
}

input[type=checkbox]:checked+label .icon,input[type=checkbox]:not(:checked)+label .icon {
    font-size: 14px;
    position: absolute;
    top: 50%;
    left: 3px;
    border-radius: 50%;
    -webkit-transition: .3s ease-in-out;
    -o-transition: .3s ease-in-out;
    transition: .3s ease-in-out
}

input[type=checkbox]:not(:checked)+label .icon {
    opacity: 0;
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0)
}

input[type=checkbox]:checked+label .icon {
    opacity: 1;
    -webkit-transform: scale(1) translateY(-50%);
    -ms-transform: scale(1) translateY(-50%);
    transform: scale(1) translateY(-50%)
}

.pagination_pages {
    margin-right: 30px
}

.pagination_pages-page {
    margin-right: 15px
}

.pagination_pages-page:last-of-type {
    margin-right: 0
}

.pagination_pages-page .page {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background: #fff;
    border: 2px solid #ededed;
    border-radius: 4px;
    width: 40px;
    height: 40px;
    font-weight: 700;
    color: #858585;
    position: relative;
    overflow: hidden;
    -webkit-transition: color .4s ease-in-out;
    -o-transition: color .4s ease-in-out;
    transition: color .4s ease-in-out
}

.pagination_pages-page .page:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 4px;
    background: -o-linear-gradient(334.8deg,#6a11cb 5.44%,#2575fc 110.17%);
    background: linear-gradient(115.2deg,#6a11cb 5.44%,#2575fc 110.17%);
    z-index: 1;
    -webkit-transition: opacity .4s ease-in-out;
    -o-transition: opacity .4s ease-in-out;
    transition: opacity .4s ease-in-out;
    opacity: 0
}

.pagination_pages-page .page-number {
    position: relative;
    z-index: 2
}

.pagination_pages-page .page.active {
    cursor: default
}

.pagination_pages-page .page.active,.pagination_pages-page .page:focus,.pagination_pages-page .page:hover {
    color: #fff;
    border-color: transparent
}

.pagination_pages-page .page.active:after,.pagination_pages-page .page:focus:after,.pagination_pages-page .page:hover:after {
    opacity: 1
}

.pagination_next {
    color: #858585;
    font-size: 24px;
    line-height: 1
}

.rating_star {
    margin-right: 13px;
    line-height: 1
}

.rating_star:last-of-type {
    margin-right: 0
}

.rating_star .icon {
    color: #f83600;
    font-size: 16px
}

.quote {
    display: block;
    quotes: none;
    font-size: 20px;
    line-height: 1.6;
    font-style: italic
}

iframe {
    width: 100%;
    height: 100%;
    border: none;
    display: block
}

.cover {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    -webkit-transition: .4s ease-in-out;
    -o-transition: .4s ease-in-out;
    transition: .4s ease-in-out;
    cursor: pointer
}

.cover.hidden {
    visibility: hidden;
    opacity: 0
}

.cover_label {
    position: absolute;
    top: 20px;
    left: 20px;
    border-radius: 10px;
    background: #3d53eb;
    z-index: 100;
    color: #fff;
    padding: 0 20px;
    height: 32px;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

.cover_media {
    position: relative;
    z-index: 1;
    height: 100%;
    overflow: hidden
}

.cover_media img {
    height: 100%
}

.cover_media:after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background: -webkit-gradient(linear,left top,left bottom,color-stop(35.39%,rgba(0,0,0,.0001)),to(#000));
    background: -o-linear-gradient(top,rgba(0,0,0,.0001) 35.39%,#000 100%);
    background: linear-gradient(180deg,rgba(0,0,0,.0001) 35.39%,#000 100%)
}

.cover_text {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 50;
    padding: 20px 40px 20px 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end
}

.cover_text-name {
    display: inline-block;
    margin-bottom: 5px;
    line-height: 1;
    color: #d5d5d5;
    font-weight: 700
}

.cover_text-title {
    color: #fff;
    font-size: 20px
}

.cover_play {
    position: absolute;
    width: 120px;
    height: 120px;
    z-index: 2;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

.cover_duration {
    position: absolute;
    font-size: 12px;
    line-height: 1;
    color: #d5d5d5;
    z-index: 5;
    right: 10px;
    bottom: 10px
}

@media screen and (min-width:767.98px) {
    .h1,h1 {
        font-size: 58px
    }

    .h2,h2 {
        font-size: 48px
    }

    .h3,h3 {
        font-size: 44px
    }

    .h4,.h5,h4,h5 {
        line-height: 1.3
    }

    .container {
        padding: 0 40px
    }
}

@media screen and (min-width:991.98px) {
    .h1,.h2,.h3,.h4,.h5,.h6,h1,h2,h3,h4,h5,h6 {
        line-height: 1.2
    }

    .h1,h1 {
        font-size: 64px
    }

    .h2,h2 {
        font-size: 52px
    }

    .h4,h4 {
        font-size: 32px
    }

    .h5,h5 {
        font-size: 24px
    }

    .panel {
        display: none!important
    }
}

@media screen and (min-width:1199.98px) {
    .container {
        padding: 0
    }
}

a[href^="http://maps.google.com/maps"],a[href^="https://maps.google.com/maps"] {
    display: none!important
}

.gm-style-cc,.gmnoprint a,.gmnoprint span {
    display: none
}

.gmnoprint div {
    background: 0 0!important
}

input[type=search]::-webkit-search-cancel-button,input[type=search]::-webkit-search-decoration,input[type=search]::-webkit-search-results-button,input[type=search]::-webkit-search-results-decoration {
    -webkit-appearance: none
}

.promobar {
    background: -webkit-gradient(linear,left top,right top,color-stop(3.62%,#6a11cb),color-stop(97.87%,#2575fc));
    background: -o-linear-gradient(left,#6a11cb 3.62%,#2575fc 97.87%);
    background: linear-gradient(90deg,#6a11cb 3.62%,#2575fc 97.87%);
    color: #fff;
    height: 62px
}

.promobar_socials {
    display: none!important
}

.promobar_main {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1
}

.promobar_main-text {
    margin-right: 15px;
    line-height: 1
}

.promobar_main-text .hide {
    display: none
}

.promobar_main .btn {
    width: 50%;
    height: 33px;
    padding: 0 20px;
    line-height: 1
}

.promobar_main .btn span {
    position: relative;
    top: 1px
}   

@media screen and (min-width:575.98px) {
    .promobar_main-text .hide {
        display: inline
    }

    .promobar_main .btn {
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content
    }
}

@media screen and (min-width:991.98px) {
    .promobar_socials {
        display: -webkit-box!important;
        display: -ms-flexbox!important;
        display: flex!important
    }

    .promobar_socials-item {
        font-size: 16px;
        line-height: 1;
        margin-right: 15px;
        position: relative;
        bottom: 0;
        -webkit-transition: bottom .3s;
        -o-transition: bottom .3s;
        transition: bottom .3s
    }

    .promobar_socials-item:last-of-type {
        margin-right: 0
    }

    .promobar_socials-item:focus,.promobar_socials-item:hover {
        bottom: 5px
    }

    .promobar_main {
        -webkit-box-flex: unset;
        -ms-flex-positive: unset;
        flex-grow: unset
    }

    .header {
        padding: 0;
        height: 84px;
        font-weight: 700;
        font-size: 16px
    }

    .header .container {
        height: 100%
    }

    .header.sticky {
        overflow-y: visible
    }

    .header.sticky+main {
        margin-top: 84px
    }

    .header_logo {
        position: relative;
        top: 3px
    }

    .header_trigger {
        display: none
    }

    .header .nav-item:not(.dropdown-item) {
        height: 100%;
        display: -webkit-inline-box;
        display: -ms-inline-flexbox;
        display: inline-flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        position: relative
    }

    .header .nav-item:not(.dropdown-item):after {
        content: '';
        position: absolute;
        width: 100%;
        height: 4px;
        bottom: 0;
        left: 0;
        background: -o-linear-gradient(338.59deg,#ff0844 24.85%,#ff8b67 95.39%);
        background: linear-gradient(111.41deg,#ff0844 24.85%,#ff8b67 95.39%);
        border-radius: 2px;
        -webkit-transform: scale(0);
        -ms-transform: scale(0);
        transform: scale(0);
        -webkit-transition: .4s ease-in-out;
        -o-transition: .4s ease-in-out;
        transition: .4s ease-in-out
    }

    .header .nav-item:not(.dropdown-item).current,.header .nav-item:not(.dropdown-item):focus,.header .nav-item:not(.dropdown-item):hover {
        color: #2b3543
    }

    .header .nav-item:not(.dropdown-item).current:after,.header .nav-item:not(.dropdown-item):focus:after,.header .nav-item:not(.dropdown-item):hover:after {
        -webkit-transform: scale(1);
        -ms-transform: scale(1);
        transform: scale(1)
    }

    .header_nav {
        display: -webkit-box!important;
        display: -ms-flexbox!important;
        display: flex!important;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        width: unset;
        height: 100%
    }

    .header_nav .promobar_socials {
        display: none!important
    }

    .header_nav-list {
        height: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        font-size: 16px;
        margin-top: 0
    }

    .header_nav-list_item:not(.header_nav-list.dropdown) {
        padding: 0 17.5px;
        margin-right: 0;
        position: relative
    }

    .header_nav-list_item:not(.header_nav-list.dropdown):after {
        content: '';
        position: absolute;
        width: 0;
        height: 4px;
        bottom: 0;
        left: 0;
        background: -o-linear-gradient(338.59deg,#ff0844 24.85%,#ff8b67 95.39%);
        background: linear-gradient(111.41deg,#ff0844 24.85%,#ff8b67 95.39%);
        border-radius: 2px
    }

    .header_nav-list_item:not(.header_nav-list.dropdown).current,.header_nav-list_item:not(.header_nav-list.dropdown):focus,.header_nav-list_item:not(.header_nav-list.dropdown):hover {
        color: #2b3543
    }

    .header_nav-list_item:not(.header_nav-list.dropdown).current:after,.header_nav-list_item:not(.header_nav-list.dropdown):focus:after,.header_nav-list_item:not(.header_nav-list.dropdown):hover:after {
        -webkit-transform: scale(1);
        -ms-transform: scale(1);
        transform: scale(1)
    }

    .header_nav-list_item {
        text-align: center;
        height: 100%;
        display: -webkit-inline-box;
        display: -ms-inline-flexbox;
        display: inline-flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center
    }

    .header_nav-list_item:last-of-type {
        margin-right: 0!important
    }

    .header_nav .dropdown-menu {
        position: absolute;
        z-index: 100;
        opacity: 0;
        top: calc(100% - 30px);
        max-height: unset;
        text-align: left;
        visibility: hidden;
        padding-top: 10px;
        -webkit-transition: opacity .3s;
        -o-transition: opacity .3s;
        transition: opacity .3s;
        width: calc(100% + 60px)
    }

    .header_nav .dropdown-menu.active {
        opacity: 1;
        visibility: visible
    }

    .header_nav .dropdown-menu .list-item:not(:last-of-type) {
        padding-bottom: 25px
    }

    .header_nav .dropdown-menu .list-item[data-main=true] {
        display: none!important
    }

    .header_nav .dropdown-list {
        background: #fff;
        -webkit-box-shadow: 0 2px 14px rgba(112,115,255,.4);
        box-shadow: 0 2px 14px rgba(112,115,255,.4);
        border-radius: 8px;
        padding: 20px;
        margin: 0
    }

    .header_nav .dropdown-toggle[aria-expanded=true] {
        padding-bottom: 0
    }

    .header_nav .dropdown-toggle .icon {
        margin-left: 10px;
        position: relative;
        top: 1px
    }
}

.hero {
    padding: 80px 0
}

.hero_main-title {
    margin-bottom: 40px;
    line-height: 1;
    color: #3d53eb
}
.pricing {
    text-align: center;
    padding-bottom: 80px
}

.pricing_header {
    max-width: 725px;
    margin: 0 auto 40px
}

.pricing_header-title {
    margin-bottom: 15px
}

.pricing_nav {
    background: #fff;
    -webkit-box-shadow: inset 0 3px 3px rgba(62,62,62,.075);
    box-shadow: inset 0 3px 3px rgba(62,62,62,.075);
    border-radius: 16.5px;
    overflow: hidden;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    max-width: 450px;
    margin: 0 auto 30px
}

.pricing_nav .nav-item {
    width: calc(100% / 3)
}

.pricing_nav .nav-link {
    text-transform: uppercase;
    font-size: 12px;
    line-height: 1;
    font-weight: 700;
    color: #979797;
    padding: 10px 0;
    border-radius: 16.5px;
    position: relative;
    width: 100%;
    overflow: hidden;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden
}

.pricing_nav .nav-link:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 34px;
    background: #3d53eb;
    -webkit-transition: opacity .3s ease-in-out;
    -o-transition: opacity .3s ease-in-out;
    transition: opacity .3s ease-in-out;
    opacity: 0;
    z-index: 1
}

.pricing_nav .nav-link_text {
    position: relative;
    z-index: 1
}

.pricing_nav .nav-link.active {
    color: #fff
}

.pricing_nav .nav-link.active:before {
    opacity: 1
}

.pricing_list-card {
    margin: 0 auto 30px;
    max-width: 350px
}

.pricing_list-card:last-of-type {
    margin-bottom: 0
}

.pricing_list-card .card-wrapper {
    background: #fff;
    -webkit-box-shadow: 0 2px 14px rgba(216,217,255,.65);
    box-shadow: 0 2px 14px rgba(216,217,255,.65);
    border-radius: 12px;
    overflow: hidden;
    padding-bottom: 45px;
    -webkit-transition: .3s ease-in-out;
    -o-transition: .3s ease-in-out;
    transition: .3s ease-in-out
}

.pricing_list-card .card-wrapper .top {
    padding: 40px;
    text-align: left;
    background-blend-mode: multiply,normal
}

.pricing_list-card .card-wrapper .top_price,.pricing_list-card .card-wrapper .top_price-price,.pricing_list-card .card-wrapper .top_title {
    color: #fff
}

.pricing_list-card .card-wrapper .top--basic {
    background: -o-linear-gradient(317.23deg,#6a11cb .03%,#2575fc 90.3%),url(../img/placeholder.jpg) center/cover no-repeat;
    background: linear-gradient(132.77deg,#6a11cb .03%,#2575fc 90.3%),url(../img/placeholder.jpg) center/cover no-repeat
}

.pricing_list-card .card-wrapper .top--advanced {
    background: -o-linear-gradient(132.53deg,#f9d423 2.34%,#f83600 91.21%),url(../img/placeholder.jpg) center/cover no-repeat;
    background: linear-gradient(317.47deg,#f9d423 2.34%,#f83600 91.21%),url(../img/placeholder.jpg) center/cover no-repeat
}

.pricing_list-card .card-wrapper .top--corporate {
    background: -o-linear-gradient(145.67deg,#0acffe 15.03%,#495aff 89.83%),url(../img/placeholder.jpg) center/cover no-repeat;
    background: linear-gradient(304.33deg,#0acffe 15.03%,#495aff 89.83%),url(../img/placeholder.jpg) center/cover no-repeat
}

.pricing_list-card .card-wrapper .top_title {
    margin-bottom: 10px
}

.pricing_list-card .card-wrapper .top_price-price {
    font-weight: 800;
    padding-right: 16px;
    margin-right: 14px;
    position: relative
}

.pricing_list-card .card-wrapper .top_price-price:after {
    content: '';
    position: absolute;
    right: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 1px;
    height: 35px;
    background: rgba(255,255,255,.4)
}

.pricing_list-card .card-wrapper .top_price span:not(.top_price-price) {
    line-height: 1
}

.pricing_list-card .card-wrapper .main_list {
    text-align: left;
    padding: 40px 30px 30px 40px
}

.pricing_list-card .card-wrapper .main_list-item {
    margin-bottom: 15px
}

.pricing_list-card .card-wrapper .main_list-item:last-of-type {
    margin-bottom: 0
}

.pricing_list-card .card-wrapper .main_list-item.exclude {
    opacity: .5
}

.pricing_list-card .card-wrapper .main_list-item .icon {
    margin-right: 20px;
    position: relative;
    top: 4px;
    color: #2b3543
}

.pricing_list-card .card-wrapper .main_list-item .text {
    font-weight: 400
}

.pricing_list-card .card-wrapper .main_btn {
    width: calc(100% - 80px);
    margin: 0 40px
}

.pricing_list-card .card-wrapper:hover {
    -webkit-box-shadow: 0 2px 14px rgba(137,139,255,.5);
    box-shadow: 0 2px 14px rgba(137,139,255,.5)
}

.pricing_text {
    max-width: 730px;
    position: relative;
    padding-top: 30px;
    margin: 40px auto 0
}

.pricing_text:before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    width: 80px;
    height: 4px;
    border-radius: 2px;
    background: -webkit-gradient(linear,left top,right top,color-stop(.03%,#6a11cb),color-stop(96.48%,#2575fc));
    background: -o-linear-gradient(left,#6a11cb .03%,#2575fc 96.48%);
    background: linear-gradient(90deg,#6a11cb .03%,#2575fc 96.48%)
}

@media screen and (min-width:767.98px) {
    .pricing {
        padding-bottom: 100px
    }

    .pricing_header {
        margin: 0 auto 50px
    }

    .pricing_header-title {
        margin-bottom: 10px
    }

    .pricing_nav {
        margin: 0 auto 60px
    }

    .pricing_list {
        margin: 0 -20px
    }

    .pricing_list-card {
        padding: 0 20px;
        max-width: unset;
        margin-bottom: 40px
    }

    .pricing_text {
        margin: 50px auto 0
    }
}

@media screen and (min-width:991.98px) {
    .pricing {
        padding-bottom: 120px
    }

    .pricing_header-title {
        margin-bottom: 15px
    }

    .pricing_nav {
        margin: 0 auto 140px
    }

    .pricing_list {
        margin: 0 -12.5px
    }

    .pricing_list-card {
        padding: 0 12.5px;
        margin-bottom: 0
    }

    .pricing_list-card[data-order="2"] {
        margin-top: -50px
    }
}

.faq {
    background: #fff;
    padding: 80px 0;
    position: relative;
    -webkit-transition: .3s ease-in-out;
    -o-transition: .3s ease-in-out;
    transition: .3s ease-in-out
}

.faq .phone,.faq .sphere {
    display: none
}

.faq .container {
    max-width: 915px;
    position: relative;
    z-index: 2
}

.faq_header {
    text-align: center;
    max-width: 725px;
    margin: 0 auto 20px
}

.faq_header-title {
    max-width: 605px;
    margin: 0 auto 15px
}

.faq_accordion {
    width: 100%
}

.faq_accordion-item {
    margin-bottom: 20px
}

.faq_accordion-item .item-wrapper {
    background: #fff;
    -webkit-box-shadow: 0 2px 14px rgba(216,217,255,.65);
    box-shadow: 0 2px 14px rgba(216,217,255,.65);
    border-radius: 8px;
    padding: 30px
}

.faq_accordion-item:last-of-type {
    margin-bottom: 0
}

.faq_accordion-item_header {
    cursor: pointer
}

.faq_accordion-item_header[aria-expanded=true] .text {
    color: #3d53eb
}

.faq_accordion-item_header[aria-expanded=true] .icon:after,.faq_accordion-item_header[aria-expanded=true] .icon:before {
    background: #3d53eb
}

.faq_accordion-item_header .text {
    max-width: calc(100% - 28px);
    font-size: 20px;
    font-weight: 600
}

.faq_accordion-item_header .icon {
    position: relative;
    width: 13px;
    height: 13px
}

.faq_accordion-item_header .icon:after,.faq_accordion-item_header .icon:before {
    content: "";
    position: absolute;
    background-color: #485870;
    -webkit-transition: -webkit-transform .25s ease-out;
    -o-transition: transform .25s ease-out;
    transition: transform .25s ease-out,-webkit-transform .25s ease-out
}

.faq_accordion-item_header .icon:before {
    top: 0;
    left: 50%;
    width: 2px;
    height: 100%;
    margin-left: -1px
}

.faq_accordion-item_header .icon:after {
    top: 50%;
    left: 0;
    width: 100%;
    height: 2px;
    margin-top: -1px
}

.faq_accordion-item_header .icon.transform:before {
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg)
}

.faq_accordion-item_header .icon.transform:after {
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg)
}

.faq_accordion-item_body {
    padding-top: 15px
}

.faq_btn {
    margin-top: 40px
}

@media screen and (min-width:767.98px) {
    .faq {
        padding: 100px 0
    }

    .faq_accordion-item_header .text {
        font-size: 20px
    }
}

@media screen and (min-width:991.98px) {
    .faq {
        padding: 100px 0 120px
    }

    .faq_header {
        margin-bottom: 40px
    }
}

@media screen and (min-width:1199.98px) {
    .faq .phone,.faq .sphere {
        display: block;
        position: absolute;
        z-index: 1;
        -webkit-transition: .3s ease-in-out;
        -o-transition: .3s ease-in-out;
        transition: .3s ease-in-out
    }

    .faq .phone {
        width: 632px;
        height: 535px;
        bottom: -60px;
        left: -97px
    }

    .faq .sphere {
        width: 377px;
        height: 379px;
        top: 60px;
        right: -103px
    }
}

@media screen and (min-width:1399.98px) {
    .faq .phone {
        left: -50px
    }

    .faq .sphere {
        right: -30px
    }
}

@media screen and (min-width:1599.98px) {
    .faq .sphere {
        right: 80px
    }
}
.modal {
    z-index: 1000000
}

.modal.swal2-backdrop-show {
    background: rgba(43,53,67,.5)
}

.modal .modal_popup-close {
    position: absolute;
    font-size: 20px;
    top: 10px;
    right: 10px;
    color: #3d53eb
}

.modal_popup {
    background: #fff;
    border-radius: 12px;
    position: relative
}

.modal_popup--team {
    overflow: hidden;
    padding: 30px
}

.modal_popup--team .media {
    border-radius: 12px;
    overflow: hidden;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    margin-bottom: 10px
}

.modal_popup--team .media::before {
    float: left;
    padding-top: 100%;
    content: ''
}

.modal_popup--team .media::after {
    display: block;
    content: '';
    clear: both
}

.modal_popup--team .media img {
    height: 100%
}

.modal_popup--team .main .icon {
    color: #3d53eb
}

.modal_popup--team .main_name {
    line-height: 1.1;
    color: #3d53eb;
    margin-bottom: 5px
}

.modal_popup--team .main_socials {
    margin: 10px 0;
    font-size: 20px
}

.modal_popup--team .main_socials-item {
    width: 21px;
    margin-right: 15px
}

.modal_popup--team .main_bio {
    margin-bottom: 20px
}

.modal_popup--team .main_about {
    margin-bottom: 30px
}

.modal_popup--team .main_about-item {
    margin-bottom: 10px
}

.modal_popup--team .main_about-item:last-of-type {
    margin-bottom: 0
}

.modal_popup--team .main_about-item .text {
    margin: 0 3px
}

.modal_popup--team .main_btn {
    text-transform: uppercase;
    color: #3d53eb;
    font-size: 14px;
    line-height: 1;
    font-weight: 900
}

.modal_popup--team .main_btn .icon {
    -webkit-transition: margin-left .3s ease-in-out;
    -o-transition: margin-left .3s ease-in-out;
    transition: margin-left .3s ease-in-out;
    margin-left: 10px;
    font-size: 14px
}

.modal_popup--team .main_btn:focus .icon,.modal_popup--team .main_btn:hover .icon {
    margin-left: 15px
}

.modal_popup--register {
    max-width: 1000px;
    padding: 30px;
    text-align: center
}

.modal_popup--register .title {
    margin-bottom: 15px
}

.modal_popup--register .subtitle {
    color: #3d53eb;
    margin: 10px 0
}

.modal_popup--register .text {
    font-weight: 400
}

.modal_popup--register .registration_form {
    margin-top: 20px
}

.modal_popup--register .registration_form .field:not(:last-of-type) {
    margin-bottom: 10px
}

.modal_popup--register .registration_form .wrapper {
    margin: 10px 0 20px;
    text-align: left;
    font-size: 14px;
    font-weight: 600
}

.modal_popup--register .registration_form .wrapper .link {
    color: #3d53eb;
    margin-left: 25px
}

.modal_popup--register .registration_form .wrapper .checkbox input[type=checkbox]:checked+label:after,.modal_popup--register .registration_form .wrapper .checkbox input[type=checkbox]:checked+label:before {
    top: 10px
}

.modal_popup--register .registration_form .btn {
    width: 100%
}

.alert_popup {
    background: #fff;
    -webkit-box-shadow: 0 2px 14px rgba(112,115,255,.4);
    box-shadow: 0 2px 14px rgba(112,115,255,.4);
    border-radius: 8px;
    min-width: 280px;
    max-width: 360px;
    display: -webkit-box!important;
    display: -ms-flexbox!important;
    display: flex!important;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 30px;
    position: relative;
    z-index: 1000000
}

.alert_popup-close {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-transition: color .3s ease-in-out;
    -o-transition: color .3s ease-in-out;
    transition: color .3s ease-in-out;
    position: absolute;
    top: 15px;
    right: 15px
}

.alert_popup-close:focus,.alert_popup-close:hover {
    color: #3d53eb
}

@media screen and (min-width:575.98px) {
    .modal_popup--team {
        padding: 40px
    }

    .modal_popup--team .modal_popup-close {
        font-size: 24px;
        top: 15px;
        right: 15px
    }

    .modal_popup--register {
        padding: 60px
    }

    .modal_popup--register .modal_popup-close {
        top: 20px;
        right: 20px
    }

    .modal_popup--register .registration_form {
        max-width: 605px;
        margin: 20px auto 0
    }

    .modal_popup--register .registration_form .wrapper {
        margin: 10px 0 20px;
        text-align: center
    }

    .modal_popup--register .registration_form .wrapper .link {
        color: #3d53eb;
        font-weight: 600
    }

    .modal_popup--register .registration_form .btn {
        width: 350px
    }
}

@media screen and (min-width:767.98px) {
    .modal_popup--team .modal_popup-close {
        top: 20px;
        right: 20px
    }

    .modal_popup--team .media {
        margin-right: 40px
    }

    .modal_popup--register .registration_form .wrapper {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center
    }

    .modal_popup--register .registration_form .wrapper .link {
        margin-left: 5px
    }
}

@media screen and (min-width:991.98px) {
    .modal_popup--team {
        padding: 75px 70px;
        max-width: 1000px
    }

    .modal_popup--team .media {
        width: 356px;
        margin-bottom: 0
    }

    .modal_popup--team .media::before {
        float: left;
        padding-top: unset;
        content: ''
    }

    .modal_popup--team .media::after {
        display: block;
        content: '';
        clear: both
    }

    .modal_popup--team .main {
        width: calc(100% - 396px)
    }
}
/*# sourceMappingURL=../sourcemaps/pricing.css.map */
.scroll-to-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    display: none;
    border-radius: 100px;
    padding: 19px;
  }
  
  .scroll-to-top.visible {
    display: block;
    z-index: 99;
    border-radius: 100px;
    padding: 19px;
}