img {
    height: auto;
    /* Make sure images are scaled correctly. */
    max-width: 100%;
    /* Adhere to container width. */
    vertical-align: middle;
}
.single-post{
    box-shadow: 0px 0px 10px 2px rgba(0,0,0,.1);
}
del {
    opacity: 0.8;
}
table,th,td {
    border: 1px solid #d1d1d1;
}
table {
    border-collapse: separate;
    border-spacing: 0;
    border-width: 1px 0 0 1px;
    margin: 0 0 1.75em;
    table-layout: fixed;
    /* Prevents HTML tables from becoming too wide */
    width: 100%;
}
caption,th,td {
    font-weight: normal;
    text-align: left;
}
th {
    border-width: 0 1px 1px 0;
    font-weight: 700;
}
td {
    border-width: 0 1px 1px 0;
}
th,td {
    padding: 0.4375em;
}
/* Placeholder text color -- selectors need to be separate to work. */
::-webkit-input-placeholder {
    color: #696969;
    font-family: 'Lora', serif;
}
:-moz-placeholder {
    color: #696969;
    font-family: 'Lora', serif;
}
::-moz-placeholder {
    color: #696969;
    font-family: 'Lora', serif;
    opacity: 1;
    /* Since FF19 lowers the opacity of the placeholder by default */
}
:-ms-input-placeholder {
    color: #696969;
    font-family: 'Lora', serif;
}

/* --- 4.0 - Forms --- */
input {
    line-height: normal;
}
button,
button[disabled]:hover,
button[disabled]:focus,
input[type="button"],
input[type="button"][disabled]:hover,
input[type="button"][disabled]:focus,
input[type="reset"],
input[type="reset"][disabled]:hover,
input[type="reset"][disabled]:focus,
input[type="submit"],
input[type="submit"][disabled]:hover,
input[type="submit"][disabled]:focus {
    background: #1a1a1a;
    border: 0;
    border-radius: 2px;
    color: #fff;
    font-family: 'Lora', serif;
    font-weight: 700;
    letter-spacing: 0.046875em;
    line-height: 1;
    padding: 0.84375em 0.875em;
}
.button-default{
    display: inline-block;
    text-decoration: none;
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    background-color: #6c5ebf;
    border-radius: 2px;
    padding: 11px 30px;
    transition: .25s ease-out;
}
.button-default:hover, .button-default:focus{
    text-decoration: none;
    color: #fff;
    background-color: #ffb400;
}
.padding-120{
    padding: 120px 0px;
}
.section-bg{
    background-color: #f5f8fa;
}
.section-header{
    text-align: center;
    max-width: 650px;
    margin: 0px auto 55px;
}
.section-header h3{
    color: #6c5ebf;
    margin-bottom: 10px;
}
.section-header p{
    line-height: 30px;
    margin: 0px;
}
.section-header.bg h3{
    color: #fff;
}
.section-header.bg p{
    color: #fff;
}
.social-default{
    overflow: hidden;
    margin: 0px;
}
.social-default li{
    list-style: none;
    display: inline-block;
    float: left;
    margin-right: 10px;
}
.social-default li:last-child{
    margin: 0px;
}
.social-default li a{
    display: inline-block;
    height: 40px;
    width: 40px;
    text-align: center;
    font-size: 14px;
    line-height: 3;
    background-color: #365899;
    color: #fff;
    border-radius: 100%;
}
.social-default li:nth-child(2) a{
    background-color: #ea4c89;
}
.social-default li:nth-child(3) a{
    background-color: #dc4a38;
}
.social-default li:nth-child(4) a{
    background-color: #1da1f2;
}
.social-default li:nth-child(5) a{
    background-color: #bd081c;
}
/*  05  - Categories
----------------------------------------------*/
.categories{
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
    margin-bottom: -50px;
}
.categories .category-title{
    display: inline-block;
    background: #ffb400;
    color: #fff;
    font-size: 18px;
    padding: 11.5px 30px;
}
.categories .category-item{
    text-align: center;
    border: 1px solid #f0f0f0;
    border-left: none;
    padding: 8px 0px 25px;
}
.categories .category-item span:before{
    font-size: 30px;
    color: #ffb400;
}
.categories .category-item a{
    display: block;
    text-decoration: none;
    font-weight: 700;
    font-size: 18px;
    color: #212121;
    margin: 0px;
}
.categories .category-item a:hover{
    color: #ffb400;
}
.categories .category-buttons{
    float: right;
}
.categories .category-button-prev, .categories .category-button-next{
    display: inline-block;
    cursor: pointer;
    height: 50px;
    width: 50px;
    background: #6c5ebf;
    color: #fff;
    text-align: center;
    float: left;
    line-height: 3;
    -webkit-transition: .25s ease-out;
    transition: .25s ease-out;
}
.categories .category-button-prev:hover, .categories .category-button-next:hover{
    background: #ffb400;
}
.categories .category-button-prev:before{
    content: "\f053";
    font-family: 'fontawesome';
}
.categories .category-button-next:before{
    content: "\f054";
    font-family: 'fontawesome';
}
.categories-two{
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
    margin-bottom: 0px;
}
.categories-two .category-items{
    margin: -9px;
    overflow: hidden;
}
.categories-two .category-item{
    width: calc(100% / 5 );
    padding: 9px;
    float: left;
    border: none;
}
.categories-two .category-item-in{
    border: 1px solid #f0f0f0;
    padding: 8px 0px 25px;
    -webkit-transition: .25s ease-out;
    transition: .25s ease-out;
}
.categories-two .category-item-in:hover{
    -webkit-box-shadow: 0px 0px 10px 0px rgba(0,0,0,.1);
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,.1);
}
.categories-three .category-item{
    -webkit-transition: .25s ease-out;
    transition: .25s ease-out;

}
.categories-three .category-item:hover{
    -webkit-box-shadow: 0px 0px 10px 0px rgba(0,0,0,.1);
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,.1);
}
.categories-three .category-three-button-prev, .categories-three .category-three-button-next{
    display: inline-block;
    cursor: pointer;
    height: 30px;
    width: 30px;
    background: #f8f8f8;
    color: #212121;
    text-align: center;
    float: left;
    line-height: 2;
    -webkit-transition: .25s ease-out;
    transition: .25s ease-out;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: 9;
}
.categories-three .category-three-button-prev:hover, .categories-three .category-three-button-next:hover{
    background: #ffb400;
    color: #fff;
}
.categories-three .category-three-button-prev{
    left: 0;
}
.categories-three .category-three-button-prev:before{
    content: "\f053";
    font-family: 'fontawesome';
}
.categories-three .category-three-button-next{
    right: 0;
}
.categories-three .category-three-button-next:before{
    content: "\f054";
    font-family: 'fontawesome';
}

/*  06  - Banner Search
----------------------------------------------*/
.banner-search{
    text-align: center;

}
.banner-search p{
    font-size: 33px;
    line-height: 1.5;
    color: #212121;
    margin-bottom: 0px;
}
.banner-search h3{
    font-size: 40px;
    color: #6c5ebf;
    margin-bottom: 40px;
}
.banner-search .bn-search-opt{
    border: 1px solid #f0f0f0;
    background: #fff;
    color: #696969;
    padding: 19px 15px;
    width: 250px;
    margin-right: 15px;
    outline: none;
}
.banner-search .bn-search-opt option{
    padding: 15px;
}
.banner-search form input{
    width: 380px !important;
}
.banner-search form button{
    padding: 21px 28px;
    background-color: #6c5ebf;
    font-size: 18px;
    font-weight: 700;
    -webkit-transition: .25s ease-out;
    transition: .25s ease-out;
}
.banner-search form button:hover{
    background-color: #ffb400;
}

/*  07  - Facility
----------------------------------------------*/
.facility .facility-item{
    text-align: center;
}
.facility .facility-item .icon{
    height: 120px;
    width: 120px;
    padding: 7px;
    border: 3px dashed #add546;
    border-radius: 100%;
    display: inline-block;
    margin-bottom: 20px;
}
.facility .facility-item .icon i{
    background-color: #add546;
    height: 100%;
    width: 100%;
    border-radius: 100%;
    display: inline-block;
    text-align: center;
}
.facility .facility-item .icon i:before{
    font-size: 40px;
    color: #fff;
    margin: 0px;
    line-height: 2.5;
    display: inline-block;
    -webkit-transition: .25s ease-out;
    transition: 1s ease-out;
}
.facility .facility-item h4{
    color: #212121;
    margin-bottom: 15px;
    -webkit-transition: .25s ease-out;
    transition: .25s ease-out;
}
.facility .facility-item:hover h4{
    color: #ffb400;
}
.facility .facility-item p{
    line-height: 26px;
    margin: 0px;
}
.facility > div > div > div:nth-child(1) .facility-item .icon{
    border: 3px dashed #add546;
}
.facility > div > div > div:nth-child(1) .facility-item .icon i{
    background-color: #add546;
}
.facility > div > div > div:nth-child(2) .facility-item .icon{
    border: 3px dashed #ffb400;
}
.facility > div > div > div:nth-child(2) .facility-item .icon i{
    background-color: #ffb400;
}
.facility > div > div > div:nth-child(3) .facility-item .icon{
    border: 3px dashed #6cabb8;
}
.facility > div > div > div:nth-child(3) .facility-item .icon i{
    background-color: #6cabb8;
}
.facility > div > div > div:nth-child(4) .facility-item .icon{
    border: 3px dashed #6c5ebf;
}
.facility > div > div > div:nth-child(4) .facility-item .icon i{
    background-color: #6c5ebf;
}


/*  08  - Services
----------------------------------------------*/
.services .service-item{
    text-align: center;
    background-color: #fff;
    padding: 30px 25px;
    margin-bottom: 30px;
    -webkit-box-shadow: 0px 0px 10px 2px rgba(0,0,0,.1);
    box-shadow: 0px 0px 10px 2px rgba(0,0,0,.1);
    -webkit-transition: .25s ease-out;
    transition: .25s ease-out;
}
.services .service-item:hover{
    -webkit-box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}
.services .service-item .icon:before{
    font-size: 60px;
    color: #ffb400;
}
.services .service-item h4{
    color: #212121;
    margin-bottom: 15px;
    -webkit-transition: .25s ease-out;
    transition: .25s ease-out;
}
.services .service-item:hover h4{
    color: #ffb400;
}
.services .service-item p{
    margin-bottom: 15px;
}
.services .service-item a{
    color: #212121;
    font-weight: 700;
    text-decoration: none;
}
.services .service-item a:hover{
    color: #ffb400;
}
.services-two > div > div > div:nth-child(1) .service-item .icon{
    color: #add546;
}
.services-two > div > div > div:nth-child(2) .service-item .icon{
    color: #ffb400;
}
.services-two > div > div > div:nth-child(3) .service-item .icon{
    color: #6cabb8;
}
.services-two > div > div > div:nth-child(4) .service-item .icon{
    color: #6c5ebf;
}
.services-two > div > div > div:nth-child(5) .service-item .icon{
    color: #0fbaf4;
}
.services-two > div > div > div:nth-child(6) .service-item .icon{
    color: #92278f;
}

/*  10  - Classes
----------------------------------------------*/
.classes .class-item{
    margin-bottom: 30px;
    -webkit-box-shadow: 0px 0px 10px 2px rgba(0,0,0,.1);
    box-shadow: 0px 0px 10px 2px rgba(0,0,0,.1);
    -webkit-transition: .25s ease-out;
    transition: .25s ease-out;
}
.classes .class-item:hover{
    -webkit-box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}
.classes .class-item .image img{
    width: 100%;
}
.classes .class-item .content{
    padding: 20px;
}
.classes .class-item .content h4 a{
    text-decoration: none;
    color: #212121;
}
.classes .class-item:hover .content h4 a{
    color: #ffb400;
}
.classes .class-item .content p{
    line-height: 30px;
    margin-bottom: 5px;
}
.classes .class-item .schedule{
    margin: 0px;
    overflow: hidden;
}
.classes .class-item .schedule li{
    list-style: none;
    display: inline-block;
    float: left;
    margin-bottom: 20px;
    font-size: 15px;
    font-weight: 700;
    text-align: center;
    width: 33.335%;
    color: #fff;
    border-right: 2px solid #f0f0f0;
}
.classes .class-item .schedule li:last-child{
    border-right: none;
}
.classes .class-item .schedule li span{
    display: block;
    line-height: 20px;
}
.classes .class-item .schedule li h5{
    font-size: 15px;
    color: #212121;
}
.classes .class-item .schedule li span{
    font-size: 16px;
    color: #6c5ebf;
}
.classes .class-button{
    text-align: center;
    margin-top: 30px;
}

/*  11  - Courses
----------------------------------------------*/
.courses .course-item{
    -webkit-box-shadow: 0px 0px 10px 2px rgba(0,0,0,.1);
    box-shadow: 0px 0px 10px 2px rgba(0,0,0,.1);
    margin-bottom: 30px;
    background-color: #fff;
    -webkit-transition: .25s ease-out;
    transition: .25s ease-out;
}
.courses .course-item:hover{
    -webkit-box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}
.courses .course-item .image{
    position: relative;
    overflow: hidden;
}
.courses .course-item .image span{
    position: absolute;
    left: 0;
    bottom: 0;
    background: #ffb400;
    color: #fff;
    font-size: 24px;
    font-weight: 700;
    width: 100px;
    height: 45px;
    text-align: center;
    line-height: 2;
    -webkit-transition: .25s ease-out;
    transition: .25s ease-out;
}
.courses .course-item .image img{
    width: 100%;
    transition: .25s ease-out;
    -webkit-transition: .25s ease-out;
}
.courses .course-item:hover .image img{
    transform: scale(1.3);
}
.courses .course-item .content{
    padding: 20px 20px 15px;
}
.courses .course-item .content h4 a{
    text-decoration: none;
    color: #212121;
}
.courses .course-item .content h4 a:hover{
    color: #ffb400;
}
.courses .course-item .content ul li{
    list-style: none;
    display: inline-block;
}
.courses .course-item .content ul li:nth-child(2){
    color: #f0f0f0;
    margin: 0px 10px;

}
.courses .course-item .content ul li span, .courses .course-item .content ul li a{
    text-decoration: none;
    color: #6c5ebf;
    -webkit-transition: .25s ease-out;
    transition: .25s ease-out;
}
.courses .course-item .content ul li:hover span, .courses .course-item .content ul li:hover a{
    color: #ffb400;
}
.courses .course-item .content p{
    margin: 0px;
    padding-bottom: 15px;
    border-bottom: 1px solid #f0f0f0;
}
.courses .course-item .bottom{
    overflow: hidden;
    padding-top: 15px;
}
.courses .course-item .bottom ul{
    margin: 0px;
    float: left;
}
.courses .course-item .bottom ul li{
    list-style: none;
    display: inline-block;
}
.courses .course-item .bottom ul li:first-child{
    margin-right: 20px;
}
.courses .course-item .bottom ul li span{
    color: #6c5ebf;
    -webkit-transition: .25s ease-out;
    transition: .25s ease-out;
}
.courses .course-item .bottom ul li:hover span{
    color: #ffb400;
}
.courses .course-item .bottom ul li a{
    text-decoration: none;
    font-weight: 700;
    color: #212121;
}
.courses .course-item .bottom .course-rating{
    float: right;
}
.courses .course-item .bottom .course-rating span{
    color: #ffb400;
}
.courses .courses-button{
    text-align: center;
    margin-top: 30px;
}
.courses .course-item-list{
    margin-bottom: 50px;
}
.courses .course-item-list .image{
    float: left;
}
.courses .course-item-list .image span{
    left: auto;
    right: 0;
    bottom: 20px;
}
.courses .course-item-list .content{
    display: table;
    padding: 25px 25px 16px;
}
.courses .course-item-list .content p{
    line-height: 28px;
    padding-bottom: 20px;
}
.courses .course-item-list .content h4{
    margin-bottom: 5px;
}
.course-single-item .image{
    margin-bottom: 35px;
}
.course-single-item h3{
    font-size: 30px;
    color: #212121;
    margin-bottom: 5px;
}
.course-single-item .course-meta{
    margin-bottom: 20px;
}
.course-single-item .course-meta li{
    list-style: none;
    display: inline-block;
    color: #888888;
    margin-right: 12px;
}
.course-single-item .course-meta li i{
    color: #ffb400;
    margin-right: 4px;
}
.course-single-item .course-meta li span{
    color: #b1b1b1;
}
.course-single-item .course-meta li a{
    color: #b1b1b1;
    text-decoration: none;
}
.course-single-item .course-meta li.rating i{
    color: #ffb400;
    margin: 0px;
}
.course-single-item h4{
    color: #6c5ebf;
    margin-bottom: 5px;
}
.course-single-item .course-features{
    margin-top: 10px;
    margin-bottom: 30px;
    -webkit-box-shadow: 0px 0px 10px 2px rgba(0,0,0,.1);
    box-shadow: 0px 0px 10px 2px rgba(0,0,0,.1);
}
.course-single-item .course-features .title{
    text-align: center;
    background-color: #ffb400;
    color: #fff;
    font-size: 18px;
    font-weight: 700;
}
.course-single-item .course-features li{
    list-style: none;
    border-bottom: 1px solid #f0f0f0;
    font-size: 16px;
    color: #696969;
    padding: 10px 25px;
}
.course-single-item .course-features li:first-child, .course-single-item .course-features li:last-child{
    border: none;
}
.course-single-item .course-features li i{
    color: #ffb400;
    margin-right: 10px;
}
.course-single-item .course-features li span{
    float: right;
}
.course-single-item .course-attachments{
    margin-bottom: 40px;
}
.course-single-item .course-attachments h4{
    margin-bottom: 15px;
}
.course-single-item .course-attachments li{
    list-style: none;
    border: 1px solid #f0f0f0;
    font-size: 16px;
    color: #696969;
    padding: 10px 20px;
    margin-bottom: 10px;
}
.course-single-item .course-attachments li a{
    color: #696969;
}
.course-single-item .course-attachments li:nth-child(odd){
    background-color: #fafafa;
}
.course-single-item .course-attachments li i{
    color: #ffb400;
    margin-right: 10px;
}
.course-single-item .course-attachments li:nth-child(even) i{
    color: #6c5ebf;
}
.course-single-item .course-attachments li span{
    float: right;
}
.course-single-item .instructor{
    margin-top: 60px;
}
.course-single-item .instructor h4{
    margin-bottom: 20px;
}
.course-single-item .instructor .content-bg{
    overflow: hidden;
}
.course-single-item .instructor .image{
    float: left;
    height: 130px;
    width: 130px;
    overflow: hidden;
    border-radius: 100%;
    margin-right: 20px;
    margin-bottom: 20px;
}
.course-single-item .instructor .content{
    display: table;
    padding: 20px 0px;
}
.course-single-item .instructor .content h5{
    font-size: 20px;
    color: #22313f;
}
.course-single-item .instructor .content span{
    font-size: 14px;
    color: #707070;
}
.course-single-item .instructor .content ul li{
    display: inline-block;
    list-style: none;
    margin-right: 10px;
}
.course-single-item .instructor .content ul li a{
    font-size: 20px;
    color: #3b5998;
}
.course-single-item .instructor .content ul li:nth-child(2) a{
    color: #db4437;
}
.course-single-item .instructor .content ul li:nth-child(3) a{
    color: #e94c89;
}
.course-single-item .instructor .content ul li:nth-child(4) a{
    color: #23b6ea;
}
.course-single-item .instructor .content ul li:nth-child(5) a{
    color: #cb1f27;
}
.course-single-item .course-reviews{
    overflow: hidden;

}
.course-single-item .course-reviews .av-rating-bg h5, .course-single-item .course-reviews .de-rating-bg h5{
    font-size: 16px;
    color: #212121;
    margin-bottom: 15px;
}
.course-single-item .course-reviews .av-rating-bg{
    float: left;
    width: 23%;
    margin-right: 30px;
    margin-bottom: 40px;
}
.course-single-item .course-reviews .av-rating{
    border: 1px solid #ebebeb;
    border-radius: 3px;
    text-align: center;
    padding: 33px 0px;
}
.course-single-item .course-reviews .av-rating-bg span{
    font-size: 60px;
    line-height: 1;
    font-weight: 700;
    color: #ffb400;
    margin-bottom: 10px;
    display: block;
}
.course-single-item .course-reviews .av-rating-bg .rating i{
    color: #ffb400;
    font-size: 14px;
}
.course-single-item .course-reviews .av-rating-bg p{
    margin: 0px;
}
.course-single-item .course-reviews .de-rating-bg{
    width: 73%;
    float: left;
    margin-bottom: 40px;
}
.course-single-item .course-reviews .de-rating{
    border: 1px solid #ebebeb;
    border-radius: 3px;
    padding: 25px 30px;
}
.course-single-item .course-reviews .de-rating .rat-item{
    overflow: hidden;
}
.course-single-item .course-reviews .de-rating .left{
    font-size: 16px;
    font-weight: 700;
    color: #212121;
    float: left;
    margin-right: 20px;
}
.course-single-item .course-reviews .de-rating .middle{
    width: 80%;
    display: inline-block;
    border: 1px solid #f0f0f0;
    border-radius: 10px;
    height: 10px;
}
.course-single-item .course-reviews .de-rating .middle .fill{
    background-color: #ffb400;
    width: 100%;
    height: 8px;
    display: block;
    border-radius: 10px;
}
.course-single-item .course-reviews .de-rating .rat-item:nth-child(2) .fill{
    width: 80%;
}
.course-single-item .course-reviews .de-rating .rat-item:nth-child(3) .fill{
    width: 60%;
}
.course-single-item .course-reviews .de-rating .rat-item:nth-child(4) .fill{
    width: 40%;
}
.course-single-item .course-reviews .de-rating .rat-item:nth-child(5) .fill{
    width: 20%;
}
.course-single-item .course-reviews .de-rating .right{
    float: right;
    color: #696969;
}
.course-single-item .review-item{
    border-bottom: 1px solid #f0f0f0;
    padding: 30px 0px;
}
.course-single-item .review-item:last-child{
    border: none;
}
.course-single-item .review-item .image{
    height: 80px;
    width: 80px;
    border-radius: 100%;
    overflow: hidden;
    float: left;
    margin-right: 20px;
}
.course-single-item .review-item .image img{
    width: 100%;
}
.course-single-item .review-item .content{
    display: table;
}
.course-single-item .review-item .content .title{
    overflow: hidden;
}
.course-single-item .review-item .content h5{
    font-size: 20px;
    font-weight: 700;
    float: left;
    margin-right: 20px;
    color: #212121;
}
.course-single-item .review-item .content span{
    float: left;
    font-size: 15px;
    line-height: 34px;
    color: #b1b1b1;
}
.course-single-item .review-item .content .course-rating{
    float: right;
    line-height: 18px;
    margin-top: 10px;
    margin-bottom: 10px;
}
.course-single-item .review-item .content .course-rating i{
    color: #ffb400;
    font-size: 14px;
}
.course-single-item .review-item p{
    margin: 0px;
}
.course-single-item .review-form{
    margin-top: 10px;
}
.course-single-item .review-form h4{
    margin-bottom: 20px;
}
.course-single-item .review-form .form-item{
    width: 100%;
    border: 1px solid #f0f0f0;
    border-radius: 3px;
    padding: 12px 15px;
    margin-bottom: 30px;
    outline: none;
    font-size: 14px;
    color: #696969;
}
.course-single-item .review-form span{
    float: left;
    margin-right: 20px;
    font-size: 14px;
    color: #696969;
    line-height: 3.4;
}
.course-single-item .review-form .course-rating{
    line-height: 3.3;
}
.course-single-item .review-form .course-rating i{
    color: #ffb400;
    font-size: 14px;
}
.course-single-item .review-form button{
    font-size: 16px;
    font-weight: 700;
    outline: none;
    padding: 18px 26px;
}

/*  13  - teachers Details
----------------------------------------------*/
.teacher-details .teacher-image img{
    width: 100%;
}
.teacher-details .teacher-content{
    margin-bottom: 40px;
}
.teacher-details .teacher-content h4{
    font-size: 24px;
    line-height: 30px;
    color: #ffb400;
}
.teacher-details .teacher-content > span{
    font-size: 16px;
    line-height: 28px;
    color: #707070;
    margin-bottom: 10px;
    display: inline-block;
}
.teacher-details .teacher-content p{
    line-height: 28px;
    margin-bottom: 20px;
}
.teacher-details .social-default{
    overflow: hidden;
    margin-bottom: 30px;
}
.teacher-details .teacher-address{
    overflow: hidden;
}
.teacher-details .teacher-address li{
    list-style: none;
    margin-bottom: 6px;
    font-size: 16px;
    color: #707070;
}
.teacher-details .teacher-address li span{
    display: inline-block;
    height: 36px;
    width: 36px;
    border: 1px solid #f0f0f0;
    border-radius: 1px;
    text-align: center;
    font-size: 18px;
    line-height: 2;
    color: #ffb400;
    margin-right: 10px;
}
.teacher-details .teacher-statement{
    margin-bottom: 50px;
}
.teacher-details .teacher-statement h4{
    color: #6c5ebf;
    margin-bottom: 10px;
}
.teacher-details .teacher-statement p{
    font-size: 16px;
    line-height: 26px;
}
.teacher-details .teacher-skills h4{
    color: #6c5ebf;
    margin-bottom: 30px;
}
.teacher-details .teacher-skills .skill-item{
    display: inline-block;
    margin-right: 44px;
    text-align: center;
}
.teacher-details .teacher-skills .skill-item:last-child{
    margin-right: 0px;
}
.teacher-details .teacher-skills .circle{
    position: relative;
    margin-bottom: 10px;
}
.teacher-details .skill-item strong{
    color: #ffb400;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateY(-50%) translateX(-50%);
}
.teacher-details .teacher-skills p{
    font-size: 16px;
    color: #696969;
    margin: 0px;
}
.teacher-details .teacher-award h4{
    color: #6c5ebf;
    margin-bottom: 20px;
}
.teacher-details .teacher-award li{
    list-style: none;
    display: inline-block;
    margin-right: 60px;
    text-align: center;
}
.teacher-details .teacher-award li:last-child{
    margin: 0px;
}
.teacher-details .teacher-award li img{
    margin: 10px auto 32px;
}
.teacher-details .teacher-award li span{
    font-size: 16px;
    color: #696969;
}

/*  14  - Features
----------------------------------------------*/
.features .feature-image img{
    width: 100%;
}
.features .features-left .feature-item{
    text-align: right;
}
.features .feature-item .content{
    display: table;
}
.features .feature-item .icon{
    display: inline-block;
    height: 70px;
    width: 70px;
    text-align: center;
    background-color: #ffc000;
    color: #fff;
    border-radius: 100%;
    margin-top: 10px;
}
.features .features-left .feature-item .icon{
    float: right;
    margin-left: 20px;
}
.features .features-right .feature-item .icon{
    float: left;
    margin-right: 20px;
}
.features .features-left .feature-item:nth-child(2) .icon{
    background-color: #fc7f0c;
}
.features .features-left .feature-item:nth-child(3) .icon{
    background-color: #0fbaf4;
}
.features .features-right .feature-item:nth-child(1) .icon{
    background-color: #e84b3a;
}
.features .features-right .feature-item:nth-child(2) .icon{
    background-color: #92278f;
}
.features .features-right .feature-item:nth-child(3) .icon{
    background-color: #ee257c;
}
.features .feature-item .icon:before{
    font-size: 30px;
    line-height: 2.2;
    display: inline-block;
    -webkit-transition: .25s ease-out;
    transition: 1s ease-out;
}
.features .feature-item:hover .icon:before{
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
}
.features .features-left .feature-item:nth-child(1) .content h4{
    color: #ffc000;
}
.features .features-left .feature-item:nth-child(2) .content h4{
    color: #fc7f0c;
}
.features .features-left .feature-item:nth-child(3) .content h4{
    color: #0fbaf4;
}
.features .features-right .feature-item:nth-child(1) .content h4{
    color: #e84b3a;
}
.features .features-right .feature-item:nth-child(2) .content h4{
    color: #92278f;
}
.features .features-right .feature-item:nth-child(3) .content h4{
    color: #ee257c;
}
.features .feature-item:last-child p{
    margin: 0px;
}
/*  18  - Partner
----------------------------------------------*/
.partner .partner-slider{
    margin-bottom: 50px;
}
.partner .partner-item{
    text-align: center;
}
.partner .partner-item img{
    margin: 0 auto;
}

/*  19  - Event
----------------------------------------------*/
.event{
    padding: 120px 0px 90px;
}
.event .event-item{
    -webkit-box-shadow: 0px 0px 10px 2px rgba(0,0,0,.1);
    box-shadow: 0px 0px 10px 2px rgba(0,0,0,.1);
    background-color: #fff;
    margin-bottom: 30px;
    -webkit-transition: .25s ease-out;
    transition: .25s ease-out;
}
.event .event-item:hover{
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}
.event .event-item .event-image{
    position: relative;
}
.event .event-item .event-image .date{
    background-color: #6c5ebf;
    height: 70px;
    width: 70px;
    border-radius: 2px;
    text-align: center;
    padding: 10px;
    position: absolute;
    bottom: 10px;
    left: 10px;
    -webkit-transition: .25s ease-out;
    transition: .25s ease-out;
}
.event .event-item:hover .event-image .date{
    background-color: #ffb400;
}
.event .event-item .event-image .date span{
    font-size: 30px;
    font-weight: 700;
    color: #fff;
    line-height: 26px;
}
.event .event-item .event-image .date p{
    font-size: 14px;
    color: #fff;
    line-height: 18px;
    margin: 0px;
}
.event .event-item .event-image img{
    width: 100%;
}
.event .event-item .event-content{
    padding: 25px;
}
.event .event-item .event-content h4{
    color: #212121;
    -webkit-transition: .25s ease-out;
    transition: .25s ease-out;
}
.event .event-item:hover .event-content h4{
    color: #ffb400;
}
.event .event-item .event-content ul{
    margin-bottom: 10px;
}
.event .event-item .event-content ul li{
    list-style: none;
    color: #6c5ebf;
}
.event .event-item .event-content ul li:last-child{
    color: #ffb400;
}
.event .event-item .event-content ul li span{
    margin-right: 5px;
}
.event .event-item .event-content p{
    margin-bottom: 22px;
}
.event .event-item .event-content a{
    padding: 8px 26px;
    background-color: #6c5ebf;
}
.event .event-item .event-content a:hover{
    background-color: #ffb400;
}
.event .event-item-list .event-image{
    float: left;
}
.event .event-item-list .event-content{
    display: table;
    padding: 27px 25px;
}
.event-single-item .event-image{
    margin-bottom: 30px;
}
.event-single-item .event-content h3{
    font-size: 30px;
    color: #212121;
}
.event-single-item .event-content .post-meta{
    margin-bottom: 20px;
}
.event-single-item .event-content .post-meta li{
    list-style: none;
    display: inline-block;
    color: #6c5ebf;
}
.event-single-item .event-content .post-meta li:last-child{
    color: #ffb400;
}
.event-single-item .event-content .post-meta li span{
    margin-right: 5px;
}
.event-single-item .event-counter{
    background-color: #f8f8f8;
    border: 1px solid #f0f0f0;
    padding: 30px;
    overflow: hidden;
    margin-bottom: 25px;
}
.event-single-item .event-counter .clock-item{
    display: inline-block;
    margin-right: 25px;
    float: left;
}
.event-single-item .event-counter span{
    font-size: 30px;
    line-height: 40px;
    font-weight: 700;
    color: #ffb400;
    float: left;
    margin-right: 5px;
}
.event-single-item .event-counter p{
    font-size: 18px;
    color: #696969;
    float: left;
    margin: 20px 0px 0px;
}
.event-single-item .event-counter button{
    float: right;
    padding: 14px 27px;
    outline: none;
    background-color: #6c5ebf;
    -webkit-transition: .25s ease-out;
    transition: .25s ease-out;
}
.event-single-item .event-counter button:hover, .event-single-item .event-counter button:focus{
    background-color: #ffb400;
}
.event-single-item .event-content h4{
    color: #6c5ebf;
    margin-bottom: 5px;
}
.event-single-item .event-features{
    margin-top: 10px;
    margin-bottom: 30px;
    -webkit-box-shadow: 0px 0px 10px 2px rgba(0,0,0,.1);
    box-shadow: 0px 0px 10px 2px rgba(0,0,0,.1);
}
.event-single-item .event-features .title{
    text-align: center;
    background-color: #ffb400;
    color: #fff;
    font-size: 18px;
    font-weight: 700;
}
.event-single-item .event-features li{
    list-style: none;
    border-bottom: 1px solid #f0f0f0;
    font-size: 16px;
    color: #696969;
    padding: 10px 25px;
}
.event-single-item .event-features li:first-child, .event-single-item .event-features li:last-child{
    border: none;
}
.event-single-item .event-features li i{
    color: #ffb400;
    margin-right: 10px;
}
.event-single-item .event-features li span{
    float: right;
}
.event-single-item .event-schedule li{
    list-style: none;
    margin-bottom: 5px;
}
.event-single-item .event-schedule li span{
    margin-right: 4px;
    color: #ffb400;
}
.event-single-item .event-map{
    height: 400px;
    width: 100%;
    margin-bottom: 40px;
}

/*  20  - Pricing
----------------------------------------------*/
.pricing .pricing-item{
    -webkit-box-shadow: 0px 0px 10px 2px rgba(0,0,0,.1);
    box-shadow: 0px 0px 10px 2px rgba(0,0,0,.1);
    -webkit-transition: .25s ease-out;
    transition: .25s ease-out;
}
.pricing .pricing-item:hover{
    -webkit-box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}
.pricing .pricing-item .title{
    overflow: hidden;
    background-color: #6c5ebf;
    padding: 20px 30px;
    -webkit-transition: .25s ease-out;
    transition: .25s ease-out;
}
.pricing .pricing-item:hover .title{
    background-color: #ffb400;
}
.pricing .pricing-item .title h3{
    font-size: 36px;
    color: #fff;
    margin-top: 8px;
}
.pricing .pricing-item .title span{
    float: right;
    font-size: 48px;
}
.pricing .pricing-item .content{
    padding: 30px 30px 0px;
    border-bottom: 1px solid #f0f0f0;
}
.pricing .pricing-item .content p{
    margin: 0px;
    border-bottom: 1px solid #f0f0f0;
    padding: 12px 0px;
}
.pricing .pricing-item .content p:last-child{
    border: none;
}
.pricing .pricing-item .content p i{
    color: #6c5ebf;
    margin-right: 20px;
    float: left;
    -webkit-transition: .25s ease-out;
    transition: .25s ease-out;
}
.pricing .pricing-item:hover .content p i{
    color: #ffb400;
}
.pricing .pricing-item .content p i.close{
    color: #888888;
    opacity: 1;
}
.pricing .pricing-item .bottom{
    padding: 30px;
    text-align: center;
}

/*  21  - Blog
----------------------------------------------*/
.blog{
    padding: 20px 0px 20px;
}
.blog .blog-item{
    background-color: #fff;
    box-shadow: 0px 0px 10px 2px rgba(0,0,0,.1);
    margin-bottom: 30px;
    -webkit-transition: .25s ease-out;
    transition: .25s ease-out;
}
.blog .blog-item:hover{
    -webkit-box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}
.blog .blog-item .blog-image{
    overflow: hidden;
}
.blog .blog-item img{
    width: 100%;
    -webkit-transition: .25s ease-out;
    transition: .25s ease-out;
    -webkit-transform: scale(1);
    transform: scale(1);
}
.blog .blog-item:hover img{
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
}
.blog .blog-item .blog-content{
    padding: 15px;
}
.blog .blog-item .blog-content h4 a{
    text-decoration: none;
    color: #212121;
}
.blog .blog-item .blog-content h4 a:hover{
    color: #ffb400;
}
.blog .blog-item .blog-content p{
    margin-bottom: 10px;
    line-height: 30px;
}
.blog .blog-item ul{
    margin: 0px;
    border-top: 1px solid #f0f0f0;
    padding: 9px 15px;
}
.blog .blog-item  ul li{
    list-style: none;
    display: inline-block;
    margin-right: 10px;
    color: #707070;
}
.blog .blog-item ul li a{
    text-decoration: none;
    font-size: 15px;
    color: #696969;
}
.blog .blog-item ul li .icon{
    margin-right: 5px;
    color: #696969;
}
.blog .blog-item ul li .icon:before{
    font-size: 18px;
}
.blog .blog-button{
    text-align: center;
}
.blog-two .blog-item{
    overflow: hidden;
}
.blog-two .blog-item .blog-content{
    padding: 30px;
}
.blog-two .blog-item-wide{
    margin-bottom: 30px;
}
.blog-two .blog-item-wide .blog-image{
    float: left;
}
.blog-two .blog-item-wide .blog-content{
    display: table;
}
.blog-two .blog-item-wide .blog-content p{
    line-height: 24px;
}
.blog-two .blog-item h4{
    font-size: 20px;
}
.blog-two .blog-item ul{
    border: none;
    padding: 9px 0px;
}
.blog-two .blog-item ul li:first-child, .blog-two .blog-item ul li:first-child a{
    color: #6c5ebf;
}
.blog-two .blog-item ul li:last-child, .blog-two .blog-item ul li:last-child a{
    color: #ffb400;
}
.blog-two .blog-item a, .blog-two .blog-item a:hover{
    text-decoration: none;
    color: #ffb400;
}
.blog-three{
    background-size: cover;
    background-position: center;
    padding: 0px;
}
.blog-three .overlay{
    background-color: rgba(0,0,0,.7);
}
.blog .blog-item-classes{}
.blog .blog-item-classes .blog-content{
    padding: 30px;
}
.blog .blog-item-classes h4{
    margin-bottom: 5px;
}
.blog .blog-item-classes ul{
    border: none;
    padding: 0px;
    margin-bottom: 10px;
}
.blog .blog-item-classes .blog-content p{
    margin-bottom: 15px;
}
.post-meta li{
    list-style: none;
    display: inline-block;
    color: #696969;
    margin-right: 20px;
}
.post-meta li a{
    text-decoration: none;
    color: #696969;
}
.post-meta li .icon{
    margin-right: 5px;
}
.post-meta li .icon:before{
    font-size: 16px;
}
.single-post .post-content{
    padding: 30px 30px 30px;
    -webkit-box-shadow: 0px 0px 10px 2px rgba(0,0,0,.1);
    box-shadow: 0px 0px 10px 2px rgba(0,0,0,.1);
    margin-bottom: 50px;
}
.single-post .post-content h3{
    font-size: 30px;
    color: #212121;
    margin-bottom: 10px;
}
.single-post .post-content blockquote{
    font-style: normal;
    color: #212121;
    font-weight: 700;
    font-size: 14px;
    border: none;
    padding: 0px 0px 0px 40px;
}
.single-post .post-content blockquote:after{
    left: 0px;
    top: 30%;
    color: #ffb400;
    font-size: 30px;
}
.single-post .post-content p:last-child{
    margin: 0px;
}
.single-post .content-bottom{
    border-top: 1px solid #f0f0f0;
    padding: 12px 0px;
    overflow: hidden;
}
.single-post .content-bottom ul{
    margin: 0px;
}
.single-post .content-bottom ul li{
    list-style: none;
    display: inline-block;
}
.single-post .content-bottom li:first-child{
    margin-right: 5px;
    font-style: italic;
    font-weight: 700;
    font-size: 18px;
    color: #ffb400;
}
.single-post .content-bottom li span{
    margin-right: 5px;
}
.single-post .content-bottom li span:before{
    color: #696969;
    font-size: 18px;
}
.single-post .post-tags{
    float: left;
}
.single-post .post-tags li a{
    font-style: normal;
    font-size: 16px;
    color: #696969;
    text-decoration: none;
}
.single-post .post-share{
    float: right;
}
.single-post .post-share li{
    margin-right: 10px;
}
.single-post .post-share li:last-child{
    margin: 0px;
}
.single-post .post-share li a{
    font-size: 16px;
    color: #3b5998
}
.single-post .post-share li:nth-child(3) a{
    color: #db4437
}
.single-post .post-share li:nth-child(4) a{
    color: #e94c89
}
.single-post .post-share li:nth-child(5) a{
    color: #23b6ea
}
.single-post .post-share li:nth-child(6) a{
    color: #cb1f27
}
.single-post .post-comments{
    margin-bottom: 20px;
}
.single-post .post-comments h3{
    font-size: 24px;
    color: #6c5ebf;
    margin-bottom: 20px;
}
.single-post .post-comments ul{
    margin: 0px;
}
.single-post .post-comments li{
    list-style: none;
    margin-bottom: 20px;
    border-bottom: 1px solid #f0f0f0;
}
.single-post .post-comments li .the-comment{
    padding-bottom: 20px;
}
.single-post .post-comments li:last-child{
    margin: 0px;
    padding: 0px;
    border: none;
}
.single-post .post-comments li ul{
    margin-left: 90px;
}
.single-post .post-comments .comment-image{
    overflow: hidden;
    height: 80px;
    width: 80px;
    border-radius: 100%;
    float: left;
    margin-right: 20px;
}
.single-post .post-comments .comment-content{
    display: table;
}
.single-post .post-comments .comment-content h4{
    float: left;
    font-size: 18px;
    color: #212121;
    margin-right: 5px;
}
.single-post .post-comments .comment-content .comm-reply{
    float: right;
}
.single-post .post-comments .comment-content .comm-reply i{
    color: #ffc000;
    font-weight: 700;
}
.single-post .post-comments .comment-content .comm-reply i:before{
    font-size: 15px;
}
.single-post .post-comments .comment-content .comm-reply a{
    font-style: italic;
    font-weight: 700;
    font-size: 16px;
    color: #696969;
}
.single-post .post-comments .comment-content span{
    color: #707070;
}
.single-post .post-comments .comment-content p{
    margin-top: 5px;
    margin-bottom: 0px;
}
.single-post .reply-form h3{
    font-size: 24px;
    color: #6c5ebf;
    margin-bottom: 30px;
}
.single-post .reply-form .com-input{
    width: 100%;
    border: 1px solid #f0f0f0;
    padding: 12px 15px;
    outline: none;
    margin-bottom: 30px;
}
.single-post .reply-form button{
    outline: none;
    padding: 17px 26px;
}

/*  22  - Sidebar
----------------------------------------------*/
.sidebar{
    -webkit-box-shadow: 0px 0px 10px 2px rgba(0,0,0,.1);
    box-shadow: 0px 0px 10px 2px rgba(0,0,0,.1);
    padding: 30px;
}
.sidebar .sidebar-item{
    margin-bottom: 50px;
}
.sidebar .sidebar-item .sidebar-title{
    font-size: 24px;
    color: #212121;
    margin-bottom: 20px;
}
.sidebar form{
    overflow: hidden;
}
.sidebar form input, .sidebar form select{
    width: 100%;
    border: 1px solid #f0f0f0;
    color: #696969;
    padding: 16px;
    outline: none;
    margin-bottom: 20px;
}
.sidebar form button{
    float: right;
    background-color: #ffb400;
    padding: 17px 26px;
    margin-top: 10px;
    outline: none;
    transition: .25s ease-out;
}
.sidebar form button:hover, .sidebar form button:focus{
    background-color: #6c5ebf;
}
.sidebar .sidebar-categories{
    margin: 0px;
}
.sidebar .sidebar-categories li{
    list-style: none;
    border-bottom: 1px solid #f0f0f0;
}
.sidebar .sidebar-categories li:last-child{
    border: none;
}
.sidebar .sidebar-categories li a{
    display: block;
    font-size: 16px;
    color: #696969;
    text-decoration: none;
    padding: 10px 0px;
}
.sidebar .sidebar-categories li:first-child a{
    padding-top: 0px;
}
.sidebar .sidebar-categories li:last-child a{
    padding-bottom: 0px;
}
.sidebar .sidebar-categories li a:hover{
    padding-left: 16px;
    color: #ffb400;
}
.sidebar .sidebar-categories li span{
    float: right;
    height: 30px;
    width: 30px;
    text-align: center;
    background-color: #fff;
    border-radius: 100%;
    display: inline-block;
    line-height: 30px;
    -webkit-transition: .25s ease-out;
    transition: .25s ease-out;
}
.sidebar .sidebar-categories li a:hover span{
    background-color: #ffb400;
    color: #fff;
}
.sidebar .sidebar-posts{
    margin: 0px;
}
.sidebar .sidebar-posts li{
    list-style: none;
    overflow: hidden;
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #f0f0f0;
}
.sidebar .sidebar-posts li:last-child{
    margin: 0px;
    padding: 0px;
    border: none;
}
.sidebar .sidebar-posts .image{
    float: left;
    margin-right: 20px;
}
.sidebar .sidebar-posts .content{
    display: table;
}
.sidebar .sidebar-posts a{
    display: block;
    text-decoration: none;
    font-size: 16px;
    line-height: 24px;
    color: #707070;
}
.sidebar .sidebar-posts li:hover a{
    color: #ffb400;
}
.sidebar .sidebar-posts span{
    font-size: 16px;
    color: #ffb400;
    float: left;
}
.sidebar .sidebar-posts .price{
    font-weight: 700;
}
.sidebar .sidebar-posts .rating{
    display: inline-block;
    margin-left: 10px;
}
.sidebar .sidebar-posts .rating span{
    margin-right: 4px;
    font-size: 14px;
    margin-top: 3px;
}
.sidebar .sidebar-gallery{
    margin: 0px;
    overflow: hidden;
    margin: -5px;
}
.sidebar .sidebar-gallery li{
    list-style: none;
    float: left;
    width: calc(100% / 3 - 1px);
    padding: 5px;
}
.sidebar .sidebar-gallery li img{
    width: 100%;
}
.sidebar .sidebar-tags{
    margin: 0px;
    overflow: hidden;
    margin: -5px;
}
.sidebar .sidebar-tags li{
    list-style: none;
    display: inline-block;
    float: left;
    margin: 5px;
}
.sidebar .sidebar-tags li a{
    text-decoration: none;
    font-size: 16px;
    color: #707070;
    border: 1px solid #f0f0f0;
    border-radius: 1px;
    padding: 7px 18px;
    display: inline-block;
}
.sidebar .sidebar-tags li a:hover{
    background-color: #ffb400;
    border-color: #ffb400;
    color: #fff;
}

/*  23 - Contact
----------------------------------------------*/
.home-contact .info-item{
    width: 50%;
    float: left;
}
.home-contact .info-item span:before{
    font-size: 30px;
    color: #ffb400;
}
.home-contact .info-item h4{
    color: #6c5ebf;
}
.home-contact .home-map{
    height: 400px;
    width: 100%;
}
.contact .contact-map{
    height: 600px;
    width: 100%;
    position: relative;
}
.contact-item{
    list-style: none;
    border-bottom: 1px solid #f0f0f0;
    padding-bottom: 15px;
    margin-bottom: 15px;
}
.contact-item:last-child{
    border: none;
    margin: 0px;
    padding: 0px;
}
.contact-item .icon{
    display: inline-block;
    float: left;
    color: #ffb400;
    margin-right: 15px;
}
.contact-item .icon:before{
    font-size: 30px;
}
.contact-item .content{
    display: table;
}
.contact-item .content h4{
    font-size: 18px;
    color: #6c5ebf;
}
.contact-item .content p{
    margin: 0px;
}
.contact-form .contact-input:nth-child(1){
    width: 48%;
    float: left;
}
.contact-form .contact-input:nth-child(2){
    width: 48%;
    float: right;
}
.contact-form .contact-input{
    width: 100%;
    margin-bottom: 10px;
    color: #696969;
    border: none;
    border-bottom: 1px solid #f0f0f0;
    padding: 15px;
    outline: none;
}
.contact-form .contact-input:focus{
    border-bottom: 1px solid #ffb400;
    color: #ffb400;
}
.contact-form .contact-button{
    background-color: #6c5ebf;
    outline: none;
    font-weight: 700;
    font-size: 16px;
    padding: 17px 30px;
    margin-top: 30px;
    -webkit-transition: .25s ease-out;
    transition: .25s ease-out;
}
.contact-form .contact-button:hover{
    background-color: #ffb400;
}

/*  24  - Product
----------------------------------------------*/
.product .product-header{
    border: 1px solid #f0f0f0;
    overflow: hidden;
    padding: 8px 20px;
    margin-bottom: 30px;
}
.product .product-header p{
    margin: 0px;
    line-height: 30px;
    font-size: 16px;
    font-weight: 700;
    color: #212121;
}
.product .product-header .icon{
    float: right;
}
.product .product-header .icon span{
    font-size: 18px;
    color: #696969;
    cursor: pointer;
    margin-left: 5px;
}
.product .product-header .icon span:hover, .product .product-header .icon .grid-active, .product .product-header .icon .list-active{
    color: #ffb400;
}
.product .product-header .icon .grid-active{
    color: #ffb400;
}
.product .product-items{
    overflow: hidden;
    margin: -15px;
}
.product .product-item-grid{
    width: 29.9%;
    overflow: hidden;
    -webkit-box-shadow: 0px 0px 10px 2px rgba(0,0,0,.1);
    box-shadow: 0px 0px 10px 2px rgba(0,0,0,.1);
    margin: 15px;
    float: left;
    text-align: center;
    -webkit-transition: .25s;
    transition: .25s;
}
.product .product-item-grid:hover{
    -webkit-box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}
.product .product-items .pro-image{
    position: relative;
}
.product .product-item-grid .cd-item{
    width: 100%!important;
    margin: 0px!important;
}
.product .product-item-grid .pro-overlay{
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background-color: #fff;
    opacity: 0;
    -webkit-transition: .25s;
    transition: .25s;
}
.product .product-item-grid:hover .pro-overlay{
    opacity: .98;
}
.product .product-item-grid .pro-content{
    padding: 20px;
    overflow: hidden;
    border-top: 1px solid #f0f0f0;
}
.product .pro-item .pro-content h4{
    font-size: 24px;
    line-height: 18px;
    color: #222222;
}
.product .pro-item .pro-content h4 a, .product .cd-item-info h4{
    font-size: 24px;
    line-height: 18px;
    color: #222222;
    text-decoration: none;
}
.product .pro-item .pro-content span, .product .cd-item-info span{
    font-size: 20px;
    font-weight: 700;
    color: #222222;
    display: block;
}
.product .product-item-grid .pro-content p{
    display: none;
}
.product .product-item-grid .pro-content .pro-button{
    display: none;
}
.product .product-item-grid .icon{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
}
.product .product-item-grid .icon:nth-child(3){
    left: 22%;
}
.product .product-item-grid .icon:nth-child(4){
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
}
.product .product-item-grid .icon:nth-child(5){
    right: 22%;
}
.product .product-item-grid .icon span{
    height: 40px;
    width: 40px;
    background-color: #6c5ebf;
    color: #fff;
    display: inline-block;
    border-radius: 100%;
    line-height: 2.5;
    margin-right: 5px;
    -webkit-transition: .25s;
    transition: .25s;
    transform: scale(0);
}
.product .product-item-grid .icon span:hover{
    background-color: #ffb400;
    color: #fff;
}
.product .product-item-grid .icon span:before{
    margin: 0px;
    font-size: 16px;
}
.product .product-item-grid .icon span:after{
    display: none;
}
.product .product-item-grid .pro-item:hover .icon span{
    transform: scale(1);
}
.product .cd-slider{
    margin-bottom: 0px;
}
.product .cd-slider li{
    list-style: none;
}
.product .cd-slider-navigation li{
    list-style: none;
}
.product .cd-slider-navigation li a{
    color: #000000;
    opacity: 1;
}
.cd-slider-navigation li a::before, .cd-slider-navigation li a::after{
    background: #000;
}
.product .cd-item-info{
    display: table;
    text-align: left;
    padding: 40px 40px 40px 0px;
}
.product .cd-item-info p{
    color: #696969;
    margin-top: 0px;
    margin-bottom: 25px;
}
.product .product-item-list{
    width: 96.6%;
    border: 1px solid #f0f0f0;
    overflow: hidden;
    margin: 15px 15px 30px 15px;
    -webkit-box-shadow: 0px 0px 10px 2px rgba(0,0,0,.1);
    box-shadow: 0px 0px 10px 2px rgba(0,0,0,.1);
    -webkit-transition: .25s ease-out;
    transition: .25s ease-out;
}
.product .product-item-list:hover{
    -webkit-box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}
.product .product-item-list .pro-content{
    display: table;
    padding: 30px;
    position: relative;
}
.product .product-item-list .pro-overlay{
    display: none;
}
.product .product-item-list .icon{
    display: none;
}
.product .product-item-list .pro-image{
    margin: 0px;
    width: 30%;
    float: left;
}
.product .product-item-list .pro-rating{
    position: absolute;
    right: 30px;
    top: 30px;
}
.pro-rating a{
    color: #f8b600;
}
.product .pro-button{
    margin: 0px;
}
.product .pro-button li{
    list-style: none;
    display: inline-block;
    margin-right: 5px;
}
.product .pro-button button{
    display: inline-block;
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    background-color: #6c5ebf;
    border-radius: 2px;
    padding: 17px 30px;
    outline: none;
    -webkit-transition: .25s ease-out;
    transition: .25s ease-out;
}
.product .pro-button button:hover{
    background-color: #ffb400;
}
.product .pro-button a{
    display: inline-block;
    text-decoration: none;
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    background-color: #6c5ebf;
    border-radius: 2px;
    padding: 11px 30px;
}
.product .pro-button a:hover{
    background-color: #ffb400;
}
.pagination{
    display: block;
    text-align: center;
    margin-top: 70px;
    overflow: hidden;
    margin-bottom: 0px;
}
.pagination li{
    margin-right: 10px;
    display: inline-block;
}
.pagination li a{
    color: #222222;
    border: 1px solid #f1f1f1;
    border-radius: 100%!important;
    height: 40px;
    width: 40px;
    line-height: 26px;
    font-weight: 600;
    text-align: center;
}
.pagination li a:hover, .pagination li a:focus, .pagination li a.active{
    background-color: #ffb400;
    color: #fff;
    border: 1px solid #ffb400;
}
.home-store .product-item-grid{
    width: 100%;
    margin: 0px;
    border: none;
    -webkit-box-shadow: 0px 0px 10px 2px rgba(0,0,0,.1);
    box-shadow: 0px 0px 10px 2px rgba(0,0,0,.1);
}
.home-store .product-item-grid .pro-content{
    border: none;
}

.por-sidebar-item{
    margin-bottom: 30px;
}
.por-sidebar-item > h4{
    margin-bottom: 20px;
    color: #212121;
}
.product-sidebar form{
    position: relative;
}
.product-sidebar form input{
    width: 100%;
    border: 1px solid #f0f0f0;
    color: #696969;
    padding: 12px;
    outline: none;
}
.product-sidebar form button{
    position: absolute;
    right: 0px;
    top: 2px;
    font-size: 18px;
    color: #696969;
    background: transparent;
    outline: none;
}
.product-sidebar .pro-categories .panel-group{
    margin: 0px;
}
.product-sidebar .pro-categories .panel-group .panel{
    border: none;
    border-radius: 0px;
    box-shadow: none;
    margin: 0px;
    padding: 10px 0px;
    border-bottom: 1px solid #f7f7f7;
}
.product-sidebar .pro-categories .panel-group .panel:first-child{
    padding-top: 0px;
}
.product-sidebar .pro-categories .panel-group .panel:last-child{
    border: none;
    padding-bottom: 0px;
}
.product-sidebar .pro-categories .panel-default>.panel-heading{
    background-color: #fff;
    border: none;
    padding: 0px;
}
.product-sidebar .pro-categories .panel-title{
    line-height: 2;
}
.product-sidebar .pro-categories .panel-title a{
    position: relative;
    display: block;
    font-style: normal;
    font-size: 16px;
    font-weight: 400;
    color: #ffb400;
    text-decoration: none;
}
.product-sidebar .pro-categories .panel-title a.collapsed{
    color: #707070;
}
.product-sidebar .pro-categories .panel-title a:hover{
    color: #ffb400;
}
.product-sidebar .pro-categories .panel-title span{
    position: absolute;
    top: 0;
    right: 0;
    float: right;
    font-size: 12px;
    font-weight: 400;
    display: inline-block;
    height: 30px;
    width: 30px;
    border: 1px solid #f7f7f7;
    border-radius: 100%;
    text-align: center;
    line-height: 2.5;
    -webkit-transition: .25s;
    transition: .25s;
}
.product-sidebar .pro-categories .panel-title span.minus{
    background-color: #ffb400;
    border: 1px solid #ffb400;
    color: #fff;
}
.product-sidebar .pro-categories .panel-title a:hover span{
    background-color: #ffb400;
    border: 1px solid #ffb400;
    color: #fff;
}
.product-sidebar .pro-categories .panel-title .collapsed span.plus{
    opacity: 1;
}
.product-sidebar .pro-categories .panel-title span.plus{
    opacity: 0;
}
.product-sidebar .pro-categories .panel-title .collapsed span.minus{
    opacity: 0;
}
.product-sidebar .pro-categories .panel-title span.minus{
    opacity: 1;
}
.product-sidebar .pro-categories .panel-body{
    padding: 0px;
    border: none!important;
}
.product-sidebar .pro-categories .panel-body a{
    display: block;
    font-size: 14px;
    color: #707070;
    text-decoration: none;
}
.product-sidebar .pro-categories .panel-body a:hover{
    color: #ffb400;
}
.product-sidebar .rel-product{
    margin: 0px;
}
.product-sidebar .rel-product li{
    list-style: none;
    border-bottom: 1px solid #f7f7f7;
    padding: 20px 0px;
}
.product-sidebar .rel-product li:first-child{
    padding-top: 0px;
}
.product-sidebar .rel-product li:last-child{
    padding-bottom: 0px;
    border: none;
}
.product-sidebar .rel-product .image{
    float: left;
    margin-right: 10px;
}
.product-sidebar .rel-product .content{
    display: table;
}
.product-sidebar .rel-product h5{
    line-height: 20px;
}
.product-sidebar .rel-product h5 a{
    font-size: 18px;
    font-weight: 700;
    color: #222222;
    text-decoration: none;
}
.product-sidebar .rel-product span{
    font-size: 16px;
    line-height: 20px;
    font-weight: 700;
    color: #222222;
    text-decoration: none;
}
.product-sidebar .rel-product .pro-rating{
    position: relative;
    line-height: 18px;
}
.product-sidebar .rel-product .pro-rating a{
    font-size: 12px;
}
.product-sidebar .pro-filter{
    position: relative;
}
.product-sidebar .pro-filter p{
    font-size: 16px;
    font-style: italic;
    font-weight: 700;
    color: #ffb400;
    margin-bottom: 10px;
}
.product-sidebar .pro-filter p span:first-child{
    color: #222222;
}
.product-sidebar .pro-filter input{
    font-size: 14px;
    font-weight: 700;
    color: #fff;
    background-color: #ffb400;
}
.pro-details{
    margin-bottom: 70px;
}
.pro-details .details h3{
    font-size: 30px;
    color: #000000;
}
.pro-details .details span{
    font-size: 30px;
    font-weight: 700;
    font-style: italic;
    color: #f8b600;
}
.pro-details .details select{
    font-size: 14px;
    color: #696969;
    border: 1px solid #f7f7f7;
    border-radius: 3px;
    padding: 8px 24px;
    margin-bottom: 20px;
    margin-right: 30px;
    outline: none;
}
.pro-details .details .qnt{
    overflow: hidden;
    margin-bottom: 20px;
}
.pro-details .details .qnt a{
    font-size: 14px;
    color: #000000;
    height: 42px;
    width: 42px;
    text-align: center;
    border: 1px solid #f7f7f7;
    border-radius: 3px;
    display: inline-block;
    line-height: 3;
    float: left;
}
.pro-details .details .qnt a:hover{
    color: #f8b600;
}
.pro-details .details .qnt input{
    font-style: normal;
    font-size: 14px;
    color: #696969;
    border: 1px solid #f7f7f7;
    padding: 8.5px 10px;
    outline: none;
    text-align: center;
    width: 56px;
    margin: 0px -1px;
    float: left;
}
.pro-details .details button{
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    outline: none;
    width: 100%;
    max-width: 370px;
    padding: 18px 15px;
}
.pro-description{
    margin-bottom: 50px;
}
.pro-description .nav-tabs{
    border-bottom: 4px solid #fafafa;
    margin-bottom: 30px;
}
.pro-description .nav-tabs>li>a{
    font-size: 20px;
    font-weight: 700;
    color: #6c5ebf;
    border: none;
    border-bottom: 4px solid #fafafa;
    margin-bottom: -3px;
    padding: 10px 0px;
    margin-right: 40px;
}
.pro-description .nav-tabs>li>a:hover{
    background: transparent;
    border: none;
}
.pro-description .nav-tabs>li.active>a, .pro-description .nav-tabs>li.active>a:focus, .pro-description .nav-tabs>li.active>a:hover{
    color: #ffb400;
    background: transparent;
    border: none;
    border-bottom: 4px solid #ffb400;
}
.pro-description .pro-reviews{
    margin-bottom: 30px;
}
.pro-description h4{
    position: relative;
    color: #6c5ebf;
}
.pro-description h4:before{
    position: absolute;
    height: 4px;
    width: 100px;
    left: 0;
    bottom: -4px;
    background-color: #65ac4c;
}
.pro-description .review-item{
    border-bottom: 1px solid #f0f0f0;
    padding: 30px 0px;
}
.pro-description .review-item:last-child{
    border: none;
}
.pro-description .review-item .image{
    height: 80px;
    width: 80px;
    border-radius: 100%;
    overflow: hidden;
    float: left;
    margin-right: 20px;
}
.pro-description .review-item .image img{
    width: 100%;
}
.pro-description .review-item .content{
    display: table;
}
.pro-description .review-item .content .title{
    overflow: hidden;
}
.pro-description .review-item .content h5{
    font-size: 20px;
    font-weight: 700;
    float: left;
    margin-right: 20px;
    color: #212121;
}
.pro-description .review-item .content span{
    float: left;
    font-size: 15px;
    line-height: 34px;
    color: #696969;
}
.pro-description .review-item .content .pro-rating{
    float: right;
    line-height: 18px;
    margin-bottom: 10px;
}
.pro-description .review-item p{
    margin: 0px;
}
.pro-description .review-form h4{
    color: #6c5ebf;
    margin-bottom: 20px;
}
.pro-description .review-form .form-item{
    width: 100%;
    border: 1px solid #f0f0f0;
    border-radius: 3px;
    padding: 12px 15px;
    margin-bottom: 30px;
    outline: none;
    font-size: 14px;
    color: #696969;
}
.pro-description .review-form span{
    float: left;
    margin-right: 20px;
    font-size: 14px;
    color: #696969;
    line-height: 3.4;
}
.pro-description .review-form .pro-rating{
    line-height: 3.3;
}
.pro-description .review-form button{
    font-size: 16px;
    font-weight: 700;
    outline: none;
    padding: 18px 26px;
}
.shop-cart .shop-table{
    margin-bottom: 60px;
}
.shop-cart table{
    border-color: #e4e7e9;
    border-radius: 2px;
    min-width: 940px;
    overflow-X: auto;
}
.shop-cart table thead{
    background-color: #6c5ebf;
}
.shop-cart table tr th{
    text-align: center;
    padding: 15px 30px;
    border: none;
    font-size: 18px;
    font-weight: 600;
    line-height: 30px;
    color: #fff;
}
.shop-cart table tr .title{
    text-align: left;
}
.shop-cart table tr td{
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    color: #000000;
    border-color: #f0f0f0;
    border-right: none;
    padding: 30px;
}
.shop-cart table tr td:last-child{
    border-right: 1px solid #f0f0f0;
}
.shop-cart table tr .product-thumbnail{
    text-align: left;
}
.shop-cart table tr .product-thumbnail .image{
    float: left;
    margin-right: 20px;
}
.shop-cart table tr .product-thumbnail .content{
    display: table;
    margin-top: 28px;
}
.shop-cart table tr .product-thumbnail h4{
    font-size: 20px;
    line-height: 24px;
}
.shop-cart table tr .product-thumbnail h4 a{
    text-decoration: none;
    color: #222222;
    font-size: 20px;
}
.shop-cart table tr img{
    border: 1px solid #f0f0f0;
}
.shop-cart table tr span{
    font-size: 20px;
    font-weight: 700;
    font-style: italic;
    line-height: 24px;
    color: #222222;
    -webkit-transition: .25s;
    transition: .25s;
}
.shop-cart table tr .quantity a{
    display: block;
    color: #222222;
    font-size: 16px;
    line-height: 36px;
    height: 42px;
    width: 42px;
    border: 1px solid #f0f0f0;
    border-radius: 2px;
    text-align: center;
    float: left;
}
.shop-cart table tr .quantity a:hover{
    background-color: #ffb400;
    border: 1px solid #ffb400;
    color: #fff!important;
}
.shop-cart table tr .quantity input{
    color: #222222;
    text-align: center;
    width: 60px;
    float: left;
    margin: 0px -1px;
    border: 1px solid #f0f0f0;
    padding: 5px 8px;
    outline: none;
    line-height: 30px;
}
.shop-cart table tr .total-cart{
    border-right: none;
}
.shop-cart table tr .remove-cart{
    width: 120px;
    text-align: right;
}
.shop-cart table tr .remove-cart span{
    height: 18px;
    width: 18px;
    background-color: #222222;
    display: inline-block;
    text-align: center;
    font-size: 12px;
    color: #fff;
    line-height: 18px;
    cursor: pointer;
}
.shop-cart table tr:hover .product-thumbnail h4 a, .shop-cart table tr:hover span, 
.shop-cart table tr:hover .quantity a, .shop-cart table tr:hover .quantity input{
    color: #ffb400;
}
.shop-cart table tr:hover .remove-cart span{
    background-color: #ffb400;
    color: #fff;
}
.shop-cart table tr .coupon{
    float: left;
}
.shop-cart table tr .coupon input:first-child{
    width: 285px;
    border: 1px solid #f0f0f0;
    border-radius: 2px 0px 0px 2px;
    padding: 8px 15px;
    outline: none;
    background-color: transparent;
    color: #696969;
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;
    float: left;
}
.shop-cart table tr .coupon input:last-child{
    background-color: #6c5ebf;
    color: #fff;
    padding: 9px 26px;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    outline: none;
    border-radius: 0px 2px 2px 0px;
    -webkit-transition: .25s ease-out;
    transition: .25s ease-out;
}
.shop-cart table tr .coupon input:last-child:hover{
    background-color: #6c5ebf;
}
.shop-cart table tr .update{
    float: right;
}
.shop-cart table tr .update input{
    background-color: #ffb400;
    color: #fff;
    padding: 9px 26px;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    outline: none;
    border-radius: 2px;
    -webkit-transition: .25s ease-out;
    transition: .25s ease-out;
}
.shop-cart table tr .update input:hover{
    background-color: #ffb400;
}
.shop-cart table tr .update input:first-child{
    color: #fff;
    background-color: #6c5ebf;
}
.shop-cart table tr .update input:first-child:hover{
    background-color: #6c5ebf;
}
.shop-cart table tr .update input:last-child{
    float: right;
    margin-left: 20px;
}
.shop-cart .shipping h4, .shop-cart .cart-total h4{
    line-height: 24px;
    color: #6c5ebf;
    margin-bottom: 20px;
}
.shop-cart .shipping select{
    font-size: 14px;
    color: #696969;
    border: 1px solid #f0f0f0;
    border-radius: 2px;
    padding: 7px 15px;
    outline: none;
    margin-bottom: 20px;
}
.shop-cart .shipping select:nth-child(2){
    width: 100%;
}
.shop-cart .shipping select:nth-child(3){
    width: 48%;
    float: left;
}
.shop-cart .shipping select:nth-child(4){
    width: 48%;
    float: right;
}
.shop-cart .shipping input{
    background-color: #6c5ebf;
    color: #fff;
    padding: 8px 26px;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    outline: none;
    border-radius: 2px;
    -webkit-transition: .25s ease-out;
    transition: .25s ease-out;
}
.shop-cart .shipping input:hover{
    background-color: #ffb400;
} 
.shop-cart .cart-total p{
    font-size: 14px;
    line-height: 32px;
    border: 1px solid #f0f0f0;
    border-radius: 2px;
    color: #696969;
    padding: 4px 15px;
    margin-bottom: 20px;
}
.shop-cart .cart-total p span{
    float: right;
    color: #fcb614;
}

/*  25  - Subscribe
----------------------------------------------*/
.subscribe{
    background-color: #ffb400;
    padding: 55px 0px;
    position: relative;
}
.subscribe h3{
    color: #fff;
    float: left;
}
.subscribe form{
    text-align: right;
}
.subscribe form input{
    outline: none;
}
.subscribe form input:first-child{
    border: 2px solid rgba(255,255,255,.5);
    padding: 16px 20px;
    background-color: #ffb400;
    color: #fff;
    border-radius: 2px;
    width: 68%;
    float: left;
}
.subscribe form input:last-child{
    background-color: #fff;
    color: #ffb400;
    font-size: 16px;
    font-weight: 700;
    padding: 20px 40px;
    margin-left: 5px;
}
.subscribe ::-webkit-input-placeholder {
    color: #fff;
    font-family: 'Lora', serif;
}
.subscribe :-moz-placeholder {
    color: #fff;
    font-family: 'Lora', serif;
}
.subscribe ::-moz-placeholder {
    color: #fff;
    font-family: 'Lora', serif;
    opacity: 1;
    /* Since FF19 lowers the opacity of the placeholder by default */
}
.subscribe :-ms-input-placeholder {
    color: #fff;
    font-family: 'Lora', serif;
}
.subscribe-two:before{
    display: none;
}

/*  26  - 404 Error
----------------------------------------------*/
.error-page{
    text-align: center;
}
.error-page .error-content{
    text-align: center;
}
.error-page .error-content h2{
    font-style: normal;
    font-size: 200px;
    line-height: 200px;
    color: #ffb400;
}
.error-page .error-content h3{
    font-size: 40px;
    color: #6c5ebf;
}
.error-page .error-content p{
    color: #212121;
    font-size: 18px;
    line-height: 30px;
}
.error-page .error-icon{
    text-align: center;
    line-height: 1;
}
.error-page .error-icon span:before{
    font-size: 370px;
    color: #6c5ebf;
}
/*  28  - Preloader
----------------------------------------------*/
.loader { 
    background: #252525;
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 9999; 
}

.spinner {
  margin: 100px auto;
  width: 60px;
  height: 60px;
  text-align: center;
  font-size: 10px;
}

.spinner > div {
    background-color: #2ecc71;
    height: 100%;
    width: 7px;
    display: inline-block;
  
    -moz-animation: stretchdelay 1.1s infinite ease-in-out;
    -webkit-animation: stretchdelay 1.1s infinite ease-in-out;
    animation: stretchdelay 1.1s infinite ease-in-out;
}

.spinner .rect2 {
    background-color: #3498db;
    -moz-animation-delay: -1.0s;
    -webkit-animation-delay: -1.0s;
    animation-delay: -1.0s;
}

.spinner .rect3 {
    background-color: #9b59b6;
    -moz-animation-delay: -0.9s;
    -webkit-animation-delay: -0.9s;
    animation-delay: -0.9s;
}

.spinner .rect4 {
    background-color: #e67e22;
    -moz-animation-delay: -0.8s;
    -webkit-animation-delay: -0.8s;
    animation-delay: -0.8s;
}

.spinner .rect5 {
    background-color: #c0392b;
    -moz-animation-delay: -0.7s;
    -webkit-animation-delay: -0.7s;
    animation-delay: -0.7s;
}

.spinner .rect6 {
    background-color: #e74c8c;
    -moz-animation-delay: -0.6s;
    -webkit-animation-delay: -0.6s;
    animation-delay: -0.6s;
}

@-moz-keyframes stretchdelay {
  0%, 40%, 100% { -moz-transform: scaleY(0.4) }  
  20% { -moz-transform: scaleY(1.0) }
}

@-webkit-keyframes stretchdelay {
  0%, 40%, 100% { -webkit-transform: scaleY(0.4) }  
  20% { -webkit-transform: scaleY(1.0) }
}

@keyframes stretchdelay {
  0%, 40%, 100% { 
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }  20% { 
    transform: scaleY(1.0);
    -webkit-transform: scaleY(1.0);
  }
}

/*-------------- Thank You ----------------*/
.scroll-to-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    display: none;
    border-radius: 100px;
    padding: 19px;
  }
  
  .scroll-to-top.visible {
    display: block;
    z-index: 99;
    border-radius: 100px;
    padding: 19px;
}